import { getFontWeightClass } from '@/utils/getFontWeightClass';
import { getTextColorClass } from '@/utils/getTextColorClass';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';

import { LOGO_MOTION_PROPS } from '../../NavbarVersion1.constants';
import type { HomeButtonProps } from './HomeButton.types';

const HomeButton: FC<HomeButtonProps> = ({
  img,
  title,
  size,
  hideLogo,
  hideLogotype,
  imgMaxHeight,
  fullWidth,
  logoAnimation = {},
  titleVariant = 'primary',
  titleWeight = 400,
}) => {
  const hoverAnimation = LOGO_MOTION_PROPS[logoAnimation.hover ?? 'none'];
  const clickAnimation = LOGO_MOTION_PROPS[logoAnimation.click ?? 'none'];
  const textColorClass = getTextColorClass(titleVariant, 'text');
  const textWeightClass = getFontWeightClass(titleWeight);
  const sizeClass = size === 'sm' ? 'lg:text-20' : 'lg:text-36';

  if (!(img || title)) return null;

  return (
    <div
      className={clsx([
        'navbar-home-button',
        'flex',
        'h-full',
        { 'w-full': fullWidth },
        'justify-center',
        { 'text-center': !img?.length },
        textColorClass,
        textWeightClass,
      ])}
    >
      <a
        href="/"
        className={clsx([
          'flex',
          'items-center',
          'gap-8',
          'text-22',
          'uppercase',
          'lg:gap-20',
          sizeClass,
        ])}
      >
        {img?.length && (
          <motion.img
            whileHover={hoverAnimation}
            whileTap={clickAnimation}
            initial={{ rotate: 0 }}
            src={img}
            alt={title}
            style={{
              maxHeight: imgMaxHeight ? `${imgMaxHeight}px` : undefined,
            }}
            className={clsx([
              'navbar-home-button-logo',
              'h-full',
              hideLogo?.mobile && 'hidden',
              hideLogo?.desktop && 'lg:hidden',
              !hideLogo?.desktop && 'lg:block',
            ])}
          />
        )}

        {title?.length && (
          <span
            className={clsx([
              'navbar-home-button-title',
              hideLogotype?.mobile && 'hidden',
              hideLogotype?.desktop && 'lg:hidden',
              !hideLogotype?.desktop && 'lg:block',
            ])}
          >
            {title}
          </span>
        )}
      </a>
    </div>
  );
};

export { HomeButton };
