import type { TargetAndTransition, VariantLabels } from 'framer-motion';

import type { NavbarAnimation } from './NavbarVersion1.types';

export const LOGO_MOTION_PROPS: Record<
  NavbarAnimation,
  TargetAndTransition | VariantLabels
> = {
  jiggle: {
    rotate: [0, 45, -40, 30, -10, 5, -2, 0],
    scale: [1, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
  none: {},
  spin: {
    rotate: 360,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};
