import clsx from 'clsx';
import type { FC } from 'react';

import type { HamburgerButtonProps } from './HamburgerButton.types';

export const HamburgerButton: FC<HamburgerButtonProps> = ({
  onClick,
  CustomIcon,
  open = false,
  size = 'md',
}) => {
  return (
    <button
      className={clsx([
        'group',
        size === 'md' && ['h-32', 'w-32'],
        size === 'lg' && ['h-full', 'w-fit'],
      ])}
      onClick={onClick}
    >
      {CustomIcon ? (
        <CustomIcon open={open} />
      ) : (
        <div className="grid gap-[6px]">
          <span
            className={`h-[3px] w-24 rounded-full bg-primary transition ${open ? 'translate-y-2.5 rotate-45 delay-200' : ''} `}
          />
          <span
            className={`h-[3px] w-24 rounded-full bg-primary ${open ? 'opacity-0 ' : ' delay-[3px]00'} transition`}
          />
          <span
            className={`h-[3px] w-24 rounded-full bg-primary transition ${open ? '-translate-y-2 -rotate-45 delay-200' : ''} `}
          />
        </div>
      )}
    </button>
  );
};
