import { useCart } from '@/hooks';
import clsx from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { BsBag as BsBagIcon } from 'react-icons/bs';
import { useIsClient } from 'usehooks-ts';

export type CartActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
  currency?: string;
};

export const CartActionButton: FC<CartActionButtonProps> = forwardRef(
  (
    {
      className,
      color,
      'aria-label': ariaLabel = 'Cart',
      children = (
        <BsBagIcon
          color={color}
          className={clsx(['text-primary', 'text-[25px]', 'lg:text-[29px]'])}
        />
      ),
      currency = 'CAD',
      ...restProps
    },
    ref
  ) => {
    const isClient = useIsClient();
    const { toggleCartDrawer, getCartCount } = useCart(currency);

    const cartCount = getCartCount();
    const badgeQty = cartCount > 99 ? '99+' : cartCount;

    return (
      <button
        {...restProps}
        ref={ref}
        className={clsx([
          'navbar-cart-button',
          'group',
          'relative',
          'flex',
          'aspect-square',
          'w-[32px]',
          'items-center',
          'justify-center',
          className,
        ])}
        aria-label={ariaLabel}
        onClick={toggleCartDrawer}
      >
        {isClient && (
          <span
            className={clsx([
              {
                'scale-100': !!badgeQty,
                'scale-0': !badgeQty,
              },
              'navbar-cart-button-badge',
              'absolute',
              '-right-2',
              '-top-2',
              'flex',
              'size-6',
              'items-center',
              'justify-center',
              'rounded-full',
              'bg-primary',
              'text-xs',
              'text-text-contrast',
              'transition-transform',
              'shadow-[1px_1px_1px_rgba(0_0_0_0.5)]',
            ])}
          >
            {badgeQty}
          </span>
        )}

        <span className="navbar-cart-button-child">{children}</span>
      </button>
    );
  }
);
