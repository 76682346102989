import { getTextColorClass } from '@/utils/getTextColorClass';
import {
  ControlledMenu,
  MenuItem,
  SubMenu,
  useHover,
  useMenuState,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import type {
  LinkGroupLinkLevel1,
  NavbarVersion2NavbarSection,
  TextColor,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { CSSProperties, FC, MouseEventHandler } from 'react';
import { useRef } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import tailwindConfig from 'tailwind.config';

import styles from './NavbarLinkItem.module.scss';
import './NavbarLinkItem.scss';

export type NavbarLinkItemProps = {
  activeTextColor?: string;
  defaultActiveColor?: TextColor;
  defaultInactiveColor?: TextColor;
  expanded?: boolean;
  fontSize?: number;
  fullWidth?: boolean;
  hasChildren?: boolean;
  href: string;
  inactiveTextColor?: string;
  label: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  childLinksDisplayStyle?: NavbarVersion2NavbarSection['value']['childLinksDisplayStyle'];
  linkGroupLevel1?: LinkGroupLinkLevel1;
};

export const NavbarLinkItem: FC<NavbarLinkItemProps> = ({
  activeTextColor,
  childLinksDisplayStyle,
  defaultActiveColor,
  expanded,
  fontSize,
  fullWidth,
  hasChildren,
  href,
  inactiveTextColor,
  label,
  onClick,
  linkGroupLevel1,
  defaultInactiveColor = 'primary',
}) => {
  const { children: linkGroupLevel2List } = linkGroupLevel1 || {};
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [menuState, toggle] = useMenuState({ transition: true });
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
  const { onClick: onClickMenu, ...restAnchorProps } = anchorProps;

  const hasChildrenOrChildLinks = !!(
    hasChildren || linkGroupLevel2List?.length
  );
  const inactiveColor =
    inactiveTextColor ||
    tailwindConfig.theme.extend.colors[
      getTextColorClass(
        defaultInactiveColor
      ) as keyof typeof tailwindConfig.theme.extend.colors
    ];
  const activeColor =
    activeTextColor ||
    (defaultActiveColor
      ? tailwindConfig.theme.extend.colors[
          getTextColorClass(
            defaultActiveColor
          ) as keyof typeof tailwindConfig.theme.extend.colors
        ]
      : tailwindConfig.theme.extend.colors['accent-1']);
  const level2InactiveColor =
    linkGroupLevel1?.childTextColor ||
    tailwindConfig.theme.extend.colors[
      getTextColorClass(
        defaultInactiveColor
      ) as keyof typeof tailwindConfig.theme.extend.colors
    ];
  const level2ActiveColor =
    linkGroupLevel1?.childActiveTextColor ||
    (defaultActiveColor
      ? tailwindConfig.theme.extend.colors[
          getTextColorClass(
            defaultActiveColor
          ) as keyof typeof tailwindConfig.theme.extend.colors
        ]
      : tailwindConfig.theme.extend.colors['accent-1']);
  const level2LinkMenuInactiveBackgroundColor =
    linkGroupLevel1?.childBackground?.type === 'color'
      ? linkGroupLevel1.childBackground.backgroundColor
      : tailwindConfig.theme.extend.colors['background-secondary'];

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (event.detail === 2 || !hasChildrenOrChildLinks) return;

    event.preventDefault();
    onClick?.(event);
    onClickMenu?.(event);
  };

  return (
    <>
      <a
        ref={anchorRef}
        className={clsx([
          'text-text-3-mob',
          'lg:text-text-3',
          { 'w-full': fullWidth },
          { 'w-fit': !fullWidth },
        ])}
        style={
          {
            fontSize: fontSize ? `${fontSize}px` : undefined,
            '--inactive-color': inactiveColor,
            '--active-color': activeColor,
          } as CSSProperties
        }
        href={href}
        onClick={handleClick}
        {...restAnchorProps}
      >
        <div
          className={clsx([
            'group',
            'flex',
            'items-center',
            'gap-8',
            'transition',
            'border-b',
            'w-fit',
            {
              [styles.linkInactive]:
                childLinksDisplayStyle === 'dropdown'
                  ? (menuState.state ?? 'closed') === 'closed'
                  : !expanded,
            },
            {
              [styles.linkActive]:
                childLinksDisplayStyle === 'dropdown'
                  ? menuState.state === 'open'
                  : expanded,
            },
          ])}
        >
          {label}

          {!!hasChildrenOrChildLinks && (
            <BsChevronDown
              size={fontSize ? `${fontSize + 2}px` : undefined}
              className={clsx([
                'transition',
                {
                  'rotate-180':
                    childLinksDisplayStyle === 'dropdown'
                      ? menuState.state === 'open'
                      : expanded,
                },
                'text-16',
                'lg:text-18',
              ])}
            />
          )}
        </div>
      </a>

      {hasChildrenOrChildLinks && childLinksDisplayStyle === 'dropdown' && (
        <ControlledMenu
          anchorRef={anchorRef}
          onClose={() => toggle(false)}
          direction="bottom"
          className={clsx(['[&_.szh-menu]:rounded-none'])}
          menuStyle={
            {
              fontSize: fontSize ? `${fontSize}px` : undefined,
              '--level-2-inactive-color': level2InactiveColor,
              '--level-2-active-color': level2ActiveColor,
              backgroundColor: level2LinkMenuInactiveBackgroundColor,
            } as CSSProperties
          }
          {...hoverProps}
          {...menuState}
        >
          {linkGroupLevel2List?.map((linkGroupLevel2Item, index) => {
            const level3InactiveColor =
              linkGroupLevel2Item?.childTextColor ||
              tailwindConfig.theme.extend.colors[
                getTextColorClass(
                  defaultInactiveColor
                ) as keyof typeof tailwindConfig.theme.extend.colors
              ];
            const level3ActiveColor =
              linkGroupLevel2Item?.childActiveTextColor ||
              (defaultActiveColor
                ? tailwindConfig.theme.extend.colors[
                    getTextColorClass(
                      defaultActiveColor
                    ) as keyof typeof tailwindConfig.theme.extend.colors
                  ]
                : tailwindConfig.theme.extend.colors['accent-1']);

            if (linkGroupLevel2Item.children.length)
              return (
                <SubMenu
                  key={`level2-${index}`}
                  label={
                    <div className={clsx(['flex', 'items-center'])}>
                      {linkGroupLevel2Item.label}

                      <BsChevronDown
                        size={fontSize ? `${fontSize + 2}px` : undefined}
                        className={clsx([
                          'right-4',
                          'absolute',
                          'transition',
                          '-rotate-90',
                          'text-16',
                          'lg:text-18',
                        ])}
                      />
                    </div>
                  }
                  arrow={false}
                  className={clsx(['transition', styles.level2Link])}
                  menuStyle={
                    {
                      backgroundColor:
                        linkGroupLevel2Item?.childBackground?.type === 'color'
                          ? linkGroupLevel2Item.childBackground.backgroundColor
                          : undefined,
                      '--level-3-inactive-color': level3InactiveColor,
                      '--level-3-active-color': level3ActiveColor,
                    } as CSSProperties
                  }
                >
                  {linkGroupLevel2Item.children.map((linkGroupLevel3Item) => {
                    return (
                      <a
                        key={`level3-${index}`}
                        href={linkGroupLevel3Item.href}
                      >
                        <MenuItem
                          className={clsx(['transition', styles.level3Link])}
                        >
                          {linkGroupLevel3Item.label}
                        </MenuItem>
                      </a>
                    );
                  })}
                </SubMenu>
              );

            return (
              <a
                key={`level2-${index}`}
                href={linkGroupLevel2Item.href}
                className="w-full"
              >
                <MenuItem className={clsx(['transition', styles.level2Link])}>
                  {linkGroupLevel2Item.label}
                </MenuItem>
              </a>
            );
          })}
        </ControlledMenu>
      )}
    </>
  );
};
