import clsx from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { BsCalendar2Event } from 'react-icons/bs';

export type BookingActionProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
};

export const BookingAction: FC<BookingActionProps> = forwardRef(
  (
    {
      onClick,
      className,
      color,
      children = (
        <BsCalendar2Event
          size={29}
          color={color}
          className={clsx(['text-primary'])}
        />
      ),
      ...restProps
    },
    ref
  ) => {
    const ariaLabel = restProps['aria-label'] ?? 'Profile';

    return (
      <button
        {...restProps}
        ref={ref}
        className={`navbar-profile-button group relative flex aspect-square w-[32px] items-center justify-center ${className}`}
        aria-label={ariaLabel}
        onClick={onClick}
      >
        <span className="navbar-profile-button-child">{children}</span>
      </button>
    );
  }
);
