// Motion
import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { Icon } from '@/components/general/Icon';
import { dropdown } from '@/constants/motionProps';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { CSSProperties, FC } from 'react';
import { Fragment } from 'react';

import type { MobileBarProps } from '.';
import { HamburgerButton, HomeButton } from '..';
import {
  BookingAction,
  OperationHoursAction,
  ProfileAction,
  SearchAction,
} from '../actions';
import { CartAction } from '../actions/CartAction';

export const MobileBar: FC<MobileBarProps> = ({
  actions,
  background,
  hamburger,
  handleOpen,
  handleSubDropdown,
  isOpen,
  isOperationHoursDrawerOpen,
  logo,
  hideLogo,
  hideLogotype,
  logotypeFontWeight,
  logotypeVariant,
  // logoAnimation,
  // logoPosition,
  logoVariant,
  // logotypePosition,
  // logotypeVariant,
  mobileOpen,
  name,
  padding,
  size,
  subDropdown,
  toggleCartDrawer,
  toggleMobile,
  toggleOperationHoursDrawer,
  toggleBookingDrawer,
  toggleSearchModal,
  currency = 'CAD',
  links = [],
  locations = [],
}) => {
  const navSizeClass = size === 'sm' ? 'h-44' : 'h-80';
  const paddingStyles: CSSProperties = {
    paddingTop: padding?.vertical ? `${padding.vertical}px` : undefined,
    paddingBottom: padding?.vertical ? `${padding.vertical}px` : undefined,
    paddingLeft: padding?.horizontal ? `${padding.horizontal}px` : undefined,
    paddingRight: padding?.horizontal ? `${padding.horizontal}px` : undefined,
  };
  const logoSrc = logoVariant === 'dark' ? logo?.dark?.src : logo?.light?.src;
  const promos = links[isOpen]?.links?.[subDropdown]?.promos ?? [];

  return (
    <BackgroundWrapper background={background}>
      <div className="relative z-10 block max-h-[calc(100vh-30px)] w-full lg:hidden">
        <div
          className={clsx([
            navSizeClass,
            'flex',
            'w-full',
            'items-center',
            'justify-between',
            'gap-2',
            'px-16',
            'backdrop-blur-md',
          ])}
          style={paddingStyles}
        >
          <HamburgerButton
            onClick={toggleMobile}
            open={mobileOpen}
            size={hamburger?.type === 'custom' ? hamburger.size : 'md'}
            CustomIcon={
              hamburger?.type === 'custom'
                ? ({ open }) => {
                    return (
                      <>
                        <img
                          className={clsx(
                            'object-contain',
                            hamburger.size !== 'lg' && [
                              'aspect-square',
                              'object-contain',
                            ],
                            hamburger.size === 'lg' && ['h-full'],
                            { hidden: open }
                          )}
                          src={hamburger.open?.src}
                          loading="lazy"
                        />

                        <img
                          className={clsx(
                            'object-contain',
                            hamburger.size !== 'lg' && [
                              'aspect-square',
                              'object-contain',
                            ],
                            hamburger.size === 'lg' && ['h-full'],
                            { hidden: !open }
                          )}
                          src={hamburger.closed?.src}
                          loading="lazy"
                        />
                      </>
                    );
                  }
                : false
            }
          />

          <HomeButton
            img={logoSrc}
            title={name}
            titleVariant={logotypeVariant}
            hideLogo={hideLogo}
            hideLogotype={hideLogotype}
            titleWeight={logotypeFontWeight}
          />

          {!!actions?.mobile?.length && (
            <ul className={clsx(['flex', 'gap-8', 'list-none', 'm-0', 'p-0'])}>
              {actions.mobile.map((action, index) => {
                if (!action.visible) return null;

                let component: JSX.Element | null = null;

                switch (action.action) {
                  case 'hours': {
                    const { color } = action;

                    component = (
                      <OperationHoursAction
                        isOpen={isOperationHoursDrawerOpen}
                        onClick={toggleOperationHoursDrawer}
                        locations={locations}
                        color={color}
                      />
                    );

                    break;
                  }
                  case 'cart': {
                    const { color } = action;

                    component = (
                      <CartAction
                        key={index}
                        onClick={toggleCartDrawer}
                        currency={currency}
                        color={color}
                      />
                    );

                    break;
                  }
                  case 'booking': {
                    const { color } = action;

                    component = (
                      <BookingAction
                        key={index}
                        onClick={toggleBookingDrawer}
                        color={color}
                      />
                    );

                    break;
                  }
                  case 'profile': {
                    const { color } = action;

                    component = (
                      <ProfileAction
                        color={color}
                        onClick={() => {
                          window.location.href = '/profile';
                        }}
                      />
                    );

                    break;
                  }
                  case 'search': {
                    const { color } = action;

                    component = (
                      <SearchAction color={color} onClick={toggleSearchModal} />
                    );

                    break;
                  }
                  default:
                    component = null;
                }

                if (!component) return null;

                return (
                  <li
                    key={index}
                    className="navbar-action-item flex items-center"
                  >
                    {component}
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {!!links?.length && (
          <motion.div
            initial="closed"
            variants={dropdown}
            animate={mobileOpen ? 'open' : 'closed'}
            className={`z-[5] flex w-full flex-col divide-y divide-background-secondary bg-transparent ${mobileOpen ? '' : 'hidden'}`}
          >
            {links.map((link, index) => {
              const isLinksLevel2Open = isOpen === index;
              const linksLevel2 = links[isOpen]?.links ?? [];
              const isLink = !link.hasChildren && !!link.href;
              const linkContentClassName = clsx([
                'link-level-1',
                'flex',
                'w-full',
                'cursor-pointer',
                'items-center',
                'gap-8',
                'p-16',
                'text-14',
                'backdrop-blur-md',
                isLinksLevel2Open && ['link-level-1-active', 'text-accent-1'],
              ]);
              const linkContent = isLink ? (
                <a href={link.href} className={linkContentClassName}>
                  {link.label}
                </a>
              ) : (
                <button
                  className={linkContentClassName}
                  onClick={() => handleOpen(index, link.links)}
                >
                  {link.label}

                  {!!link.links?.length && (
                    <Icon
                      icon="chevronDown"
                      width={12}
                      className={clsx([
                        'link-level-1-chevron-icon',
                        'transition',
                        !isLinksLevel2Open && ['fill-text-primary', 'rotate-0'],
                        isLinksLevel2Open && [
                          'link-level-1-chevron-icon-active',
                          'fill-accent-1',
                          'rotate-180',
                        ],
                      ])}
                    />
                  )}
                </button>
              );

              return (
                <div key={index}>
                  {linkContent}

                  {!!linksLevel2?.length && (
                    <motion.div
                      initial="closed"
                      variants={dropdown}
                      animate={isLinksLevel2Open ? 'open' : 'closed'}
                      className={clsx([
                        'z-[5]',
                        'flex',
                        'w-full',
                        'flex-col',
                        'divide-y',
                        'divide-background-secondary',
                      ])}
                    >
                      {linksLevel2.map((subLink, subLinkIndex) => {
                        const isSubLinkLink =
                          !subLink.hasChildren && !!subLink.href;
                        const linksLevel3 =
                          links[index]?.links?.[subLinkIndex]?.links ?? [];
                        const isLinksLevel3Open =
                          isLinksLevel2Open && subDropdown === subLinkIndex;
                        const isSubLinkOpenExpanded =
                          isLinksLevel2Open && subDropdown === subLinkIndex;
                        const subLinkContentClassName = clsx([
                          'link-level-2',
                          'flex',
                          'w-full',
                          'items-center',
                          'justify-between',
                          !isSubLinkOpenExpanded && 'text-text-primary',
                          isSubLinkOpenExpanded && [
                            'link-level-2-active',
                            'text-accent-1',
                          ],
                        ]);
                        const subLinkContent = isSubLinkLink ? (
                          <a
                            href={subLink.href}
                            className={subLinkContentClassName}
                          >
                            {subLink.label}
                          </a>
                        ) : (
                          <button
                            onClick={() => handleSubDropdown(subLinkIndex)}
                            className={subLinkContentClassName}
                          >
                            {subLink.label}

                            {!!subLink.links?.length && (
                              <Icon
                                icon="chevronDown"
                                width={12}
                                className={clsx([
                                  'link-level-2-chevron-icon',
                                  'transition',
                                  !isSubLinkOpenExpanded && [
                                    'fill-text-primary',
                                    'rotate-0',
                                  ],
                                  isSubLinkOpenExpanded && [
                                    'link-level-2-chevron-icon-active',
                                    'fill-accent-1',
                                    'rotate-180',
                                  ],
                                ])}
                              />
                            )}
                          </button>
                        );

                        return (
                          <Fragment key={subLinkIndex}>
                            <div className="bg-background-secondary/80 p-16 pl-32 text-12 backdrop-blur-md">
                              {subLinkContent}
                            </div>

                            {!!linksLevel3?.length && (
                              <motion.div
                                initial="closed"
                                variants={dropdown}
                                animate={isLinksLevel3Open ? 'open' : 'closed'}
                                className={clsx([
                                  'z-[5]',
                                  'flex',
                                  'w-full',
                                  'flex-col',
                                  'divide-y',
                                  'divide-background-secondary',
                                  'bg-white/80',
                                  'backdrop-blur-md',
                                ])}
                              >
                                {linksLevel3.map(
                                  (subLinkLink, subLinkLinkIndex) => (
                                    <div
                                      className="p-16 pl-32 text-12"
                                      key={subLinkLinkIndex}
                                    >
                                      <a
                                        href={subLinkLink.href}
                                        className="link-level-3 flex w-full items-center justify-between"
                                      >
                                        {subLinkLink.label}
                                      </a>
                                    </div>
                                  )
                                )}
                              </motion.div>
                            )}
                          </Fragment>
                        );
                      })}
                    </motion.div>
                  )}
                </div>
              );
            })}

            {!!promos?.length && (
              <div className="bg-white/80 p-16 backdrop-blur-md">
                {promos.map((promo, index) => {
                  if (index > 0) return null;

                  const isLink = !!promo.href?.trim().length;
                  const className = clsx([
                    'mt-8',
                    'inline-block',
                    'text-16',
                    'font-bold',
                    'text-accent-1',
                  ]);
                  const content = (
                    <>
                      <div className="aspect-[4/3] w-full ">
                        <img
                          src={promo.image?.src}
                          alt={promo.image?.alt}
                          loading="lazy"
                          className="size-full object-cover"
                        />
                      </div>

                      {promo.caption}
                    </>
                  );

                  if (isLink)
                    return (
                      <a key={index} href={promo.href} className={className}>
                        {content}
                      </a>
                    );

                  return (
                    <div key={index} className={className}>
                      {content}
                    </div>
                  );
                })}
              </div>
            )}
          </motion.div>
        )}
      </div>
    </BackgroundWrapper>
  );
};
