import clsx from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { BsPerson } from 'react-icons/bs';

export type ProfileActionProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
};

export const ProfileAction: FC<ProfileActionProps> = forwardRef(
  (
    {
      onClick,
      className,
      color,
      children = (
        <BsPerson
          size={38.75}
          color={color}
          className={clsx(['text-primary'])}
        />
      ),
      ...restProps
    },
    ref
  ) => {
    const ariaLabel = restProps['aria-label'] ?? 'Profile';

    const handleButtonClick = () => {
      window.location.href = '/profile';
    };

    return (
      <button
        {...restProps}
        ref={ref}
        className={`navbar-profile-button group relative flex aspect-square w-[32px] items-center justify-center ${className}`}
        aria-label={ariaLabel}
        onClick={onClick ?? handleButtonClick}
      >
        <span className="navbar-profile-button-child">{children}</span>
      </button>
    );
  }
);
