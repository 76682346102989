import type {
  Location,
  NavbarVersion3NavbarSectionActionButtonsItem,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

import {
  BookingActionButton,
  CartActionButton,
  HoursActionButton,
  ProfileActionButton,
  SearchActionButton,
} from './actionButtons';

export type NavbarActionButton = { color?: string; visible?: boolean } & (
  | {
      type: 'booking';
    }
  | {
      type: 'cart';
      currency?: string;
    }
  | {
      type: 'hours';
      locations?: Location[];
    }
);

export type NavbarActionButtonsItemProps = Omit<
  NavbarVersion3NavbarSectionActionButtonsItem['value'],
  'desktop' | 'mobile'
> & {
  locations?: Location[];
  currency?: string;
  actionButtons?:
    | NavbarVersion3NavbarSectionActionButtonsItem['value']['desktop']
    | NavbarVersion3NavbarSectionActionButtonsItem['value']['mobile'];
  containerClassName?: string;
};

export const NavbarActionButtonsItem: FC<NavbarActionButtonsItemProps> = ({
  align,
  containerClassName,
  locations,
  currency = 'CAD',
  actionButtons = [],
}) => {
  if (!actionButtons.length) return null;

  let alignmentClass = 'justify-start';

  switch (align) {
    case 'center': {
      alignmentClass = 'justify-center';
      break;
    }
    case 'left': {
      alignmentClass = 'justify-start';
      break;
    }
    case 'right': {
      alignmentClass = 'justify-end';
      break;
    }
    default: {
      alignmentClass = 'justify-start';
      break;
    }
  }

  return (
    <div
      className={clsx([
        'flex',
        'gap-32',
        'gap-y-16',
        'w-full',
        'lg:[&>*:not(:last-child)]:border-r',
        'lg:bg-primary',
        'lg:border-t',
        'lg:border-text-contrast',
        'lg:gap-0',
        'lg:min-h-64',
        'lg:text-text-contrast',
        '[&>*]:flex-1',
        alignmentClass,
        containerClassName,
      ])}
    >
      {actionButtons.map((actionButton, index) => {
        if (!actionButton.visible) return null;

        const key = `${actionButton.type}-${index}`;

        switch (actionButton.type) {
          case 'booking':
            return <BookingActionButton key={key} color={actionButton.color} />;
          case 'cart':
            return (
              <CartActionButton
                key={key}
                currency={currency}
                color={actionButton.color}
              />
            );
          case 'hours':
            return (
              <HoursActionButton
                key={key}
                locations={locations}
                color={actionButton.color}
                {...actionButton.value}
              />
            );
          case 'profile':
            return <ProfileActionButton key={key} color={actionButton.color} />;
          case 'search':
            return <SearchActionButton key={key} color={actionButton.color} />;
          default:
            return null;
        }
      })}
    </div>
  );
};
