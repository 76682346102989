import type {
  Location,
  NavbarVersion2NavbarSectionActionButtonsItem,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

import {
  BookingActionButton,
  CartActionButton,
  HoursActionButton,
  ProfileActionButton,
  SearchActionButton,
} from './actionButtons';

export type NavbarActionButton = { color?: string; visible?: boolean } & (
  | {
      type: 'booking';
    }
  | {
      type: 'cart';
      currency?: string;
    }
  | {
      type: 'hours';
      locations?: Location[];
    }
);

export type NavbarActionButtonsItemProps = Omit<
  NavbarVersion2NavbarSectionActionButtonsItem['value'],
  'desktop' | 'mobile'
> & {
  locations?: Location[];
  currency?: string;
  actionButtons?:
    | NavbarVersion2NavbarSectionActionButtonsItem['value']['desktop']
    | NavbarVersion2NavbarSectionActionButtonsItem['value']['mobile'];
  containerClassName?: string;
};

export const NavbarActionButtonsItem: FC<NavbarActionButtonsItemProps> = ({
  align,
  containerClassName,
  locations,
  currency = 'CAD',
  actionButtons = [],
}) => {
  if (!actionButtons.length) return null;

  let alignmentClass = 'justify-start';

  switch (align) {
    case 'center': {
      alignmentClass = 'justify-center';
      break;
    }
    case 'left': {
      alignmentClass = 'justify-start';
      break;
    }
    case 'right': {
      alignmentClass = 'justify-end';
      break;
    }
    default: {
      alignmentClass = 'justify-start';
      break;
    }
  }

  return (
    <div
      className={clsx([
        'gap-32',
        'flex',
        'w-full',
        'flex-wrap',
        'gap-y-16',
        alignmentClass,
        containerClassName,
      ])}
    >
      {actionButtons.map((desktopActionButton, index) => {
        if (!desktopActionButton.visible) return null;

        const key = `${desktopActionButton.type}-${index}`;

        switch (desktopActionButton.type) {
          case 'booking':
            return (
              <BookingActionButton
                key={key}
                color={desktopActionButton.color}
              />
            );
          case 'cart':
            return (
              <CartActionButton
                key={key}
                currency={currency}
                color={desktopActionButton.color}
              />
            );
          case 'hours':
            return (
              <HoursActionButton
                key={key}
                locations={locations}
                color={desktopActionButton.color}
                {...desktopActionButton.value}
              />
            );
          case 'profile':
            return (
              <ProfileActionButton
                key={key}
                color={desktopActionButton.color}
              />
            );
          case 'search':
            return (
              <SearchActionButton key={key} color={desktopActionButton.color} />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
