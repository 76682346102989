import clsx from 'clsx';
import { forwardRef } from 'react';
import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  MouseEventHandler,
} from 'react';
import { BsPerson as BsPersonIcon } from 'react-icons/bs';

export type ProfileActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
};

export const ProfileActionButton: FC<ProfileActionButtonProps> = forwardRef(
  (
    {
      className,
      color,
      onClick,
      'aria-label': ariaLabel = 'Profile',
      children = (
        <BsPersonIcon
          color={color}
          className={clsx(['text-primary', 'text-[33px]', 'lg:text-[38.75px]'])}
        />
      ),
      ...restProps
    },
    ref
  ) => {
    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (onClick) {
        onClick(event);
      } else {
        window.location.href = '/profile';
      }
    };

    return (
      <button
        {...restProps}
        ref={ref}
        className={clsx([
          'navbar-profile-button',
          'group',
          'relative',
          'flex',
          'aspect-square',
          'w-[32px]',
          'items-center',
          'justify-center',
          className,
        ])}
        aria-label={ariaLabel}
        onClick={handleButtonClick}
      >
        <span className="navbar-profile-button-child">{children}</span>
      </button>
    );
  }
);
