import type {
  LinkGroupLinkLevel1,
  NavbarVersion2NavbarSection,
  NavbarVersion2NavbarSectionLinkGroupItem,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';
import { useState } from 'react';

import { NavbarLinkItem } from './components';

export type NavbarLinkGroupItemProps =
  NavbarVersion2NavbarSectionLinkGroupItem['value'] & {
    onClick?: (linkGroupLevel1: LinkGroupLinkLevel1) => void;
    childLinksDisplayStyle?: NavbarVersion2NavbarSection['value']['childLinksDisplayStyle'];
  };

export const NavbarLinkGroupItem: FC<NavbarLinkGroupItemProps> = ({
  align,
  childLinksDisplayStyle,
  linkGroup,
  onClick,
}) => {
  const [expandedLinkGroupLevel1, setExpandedLinkGroupLevel1] = useState(-1);

  if (!linkGroup?.items.length) return null;

  let alignmentClass = 'justify-start';

  switch (align) {
    case 'center': {
      alignmentClass = 'justify-center';
      break;
    }
    case 'left': {
      alignmentClass = 'justify-start';
      break;
    }
    case 'right': {
      alignmentClass = 'justify-end';
      break;
    }
    case 'space-between': {
      alignmentClass = 'justify-between';
      break;
    }
    default: {
      alignmentClass = 'justify-start';
    }
  }

  const handleNavbarLinkItemClick = (
    linkGroupLevel1: LinkGroupLinkLevel1,
    index: number
  ) => {
    onClick?.(linkGroupLevel1);
    setExpandedLinkGroupLevel1(expandedLinkGroupLevel1 === index ? -1 : index);
  };

  return (
    <ul
      className={clsx([
        'navbar-v2-link-group-level-1',
        'navbar-v2-link-group-level-1-desktop',
        'w-full',
        'flex',
        'whitespace-nowrap',
        'flex-wrap',
        'list-none',
        'gap-24',
        'px-12',
        'py-18',
        'm-0',
        alignmentClass,
      ])}
    >
      {linkGroup.items.map((level1Item, index) => {
        const level1ItemKey = `${level1Item.label}-${index}`;

        return (
          <li key={level1ItemKey}>
            <NavbarLinkItem
              href={level1Item.href || '#'}
              label={level1Item.label}
              linkGroupLevel1={level1Item}
              childLinksDisplayStyle={childLinksDisplayStyle}
              expanded={expandedLinkGroupLevel1 === index}
              onClick={() => handleNavbarLinkItemClick(level1Item, index)}
              defaultInactiveColor={linkGroup.defaultTextColor}
              inactiveTextColor={linkGroup.textColor}
              defaultActiveColor={linkGroup.defaultActiveTextColor}
              activeTextColor={linkGroup.activeTextColor}
            />
          </li>
        );
      })}
    </ul>
  );
};
