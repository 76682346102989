import { useCart } from '@/hooks';
import clsx from 'clsx';
import { type FC, forwardRef } from 'react';
import { BsBag } from 'react-icons/bs';

import styles from './CartAction.module.css';
import type { CartActionProps } from './CartAction.types';

export const CartAction: FC<CartActionProps> = forwardRef(
  (
    {
      onClick,
      className,
      color,
      children = (
        <BsBag size={29} color={color} className={clsx(['text-primary'])} />
      ),
      currency = 'CAD',
      ...restProps
    },
    ref
  ) => {
    const ariaLabel = restProps['aria-label'] ?? 'Cart';
    const { toggleCartDrawer, getCartCount } = useCart(currency);
    const cartCount = getCartCount();
    const badgeQty = cartCount > 99 ? '99+' : cartCount;

    return (
      <button
        {...restProps}
        ref={ref}
        className={`navbar-cart-button group relative flex aspect-square w-[32px] items-center justify-center ${className}`}
        aria-label={ariaLabel}
        onClick={onClick ?? toggleCartDrawer}
      >
        <span
          className={clsx([
            styles.qtyBadge,
            {
              'scale-100': !!badgeQty,
              'scale-0': !badgeQty,
            },
            'navbar-cart-button-badge',
            'absolute',
            '-right-2',
            '-top-2',
            'flex',
            'size-6',
            'items-center',
            'justify-center',
            'rounded-full',
            'bg-primary',
            'text-xs',
            'text-text-contrast',
            'transition-transform',
          ])}
        >
          {badgeQty}
        </span>

        <span className="navbar-cart-button-child">{children}</span>
      </button>
    );
  }
);
