import type { NavbarVersion2NavbarSectionTextItem } from '@wavebi/tsunami-cms-admin';
import type { FC } from 'react';

export type NavbarTextItemProps = NavbarVersion2NavbarSectionTextItem['value'];

export const NavbarTextItem: FC<NavbarTextItemProps> = ({
  content,
  horizontalAlign,
  verticalAlign,
}) => {
  return (
    <div
      style={{
        verticalAlign,
        textAlign: horizontalAlign,
      }}
    >
      {content}
    </div>
  );
};
