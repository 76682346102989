import { CtaButton } from '@/components/general/CtaButton';
import { getCtaButtonVariant } from '@/utils/getCtaButtonVariant';
import type { NavbarVersion2NavbarSectionCtaButtonItem } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { CSSProperties, FC } from 'react';

import styles from './NavbarCtaButtonItem.module.scss';

export type NavbarCtaButtonItemProps =
  NavbarVersion2NavbarSectionCtaButtonItem['value'];

export const NavbarCtaButtonItem: FC<NavbarCtaButtonItemProps> = ({
  action,
  horizontalPadding,
  verticalPadding,
}) => {
  const smVerticalPadding =
    typeof verticalPadding.sm === 'number'
      ? `${verticalPadding.sm}px`
      : verticalPadding.sm;
  const smHorizontalPadding =
    typeof horizontalPadding.sm === 'number'
      ? `${horizontalPadding.sm}px`
      : horizontalPadding.sm;
  const mdVerticalPadding =
    typeof verticalPadding.md === 'number'
      ? `${verticalPadding.md}px`
      : verticalPadding.md;
  const mdHorizontalPadding =
    typeof horizontalPadding.md === 'number'
      ? `${horizontalPadding.md}px`
      : horizontalPadding.md;
  const lgVerticalPadding =
    typeof verticalPadding.lg === 'number'
      ? `${verticalPadding.lg}px`
      : verticalPadding.lg;
  const lgHorizontalPadding =
    typeof horizontalPadding.lg === 'number'
      ? `${horizontalPadding.lg}px`
      : horizontalPadding.lg;
  const smValue = `${smVerticalPadding} ${smHorizontalPadding}`;
  const mdValue = `${mdVerticalPadding} ${mdHorizontalPadding}`;
  const lgValue = `${lgVerticalPadding} ${lgHorizontalPadding}`;

  return (
    <div
      className={clsx(['size-full', 'flex', 'justify-center', 'items-center'])}
    >
      <CtaButton
        type={action.type}
        variant={getCtaButtonVariant(action)}
        action={action.type === 'action' ? action.action : undefined}
        url={
          action.type !== 'action' && action.type !== 'none'
            ? action.href
            : undefined
        }
        script={action.script}
        className={clsx([
          'p-0',
          '!text-text-3',
          'w-full',
          'lg:w-auto',
          styles.navbarCtaButtonItem,
        ])}
        style={
          {
            '--smValue': smValue,
            '--mdValue': mdValue,
            '--lgValue': lgValue,
          } as CSSProperties
        }
      >
        {action.label}
      </CtaButton>
    </div>
  );
};
