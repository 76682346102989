import { Icon } from '@/components/general/Icon';
import { usePreferredLocation } from '@/nanostores/PreferredLocation/hooks';
import clsx from 'clsx';
import { type FC, useMemo } from 'react';

import { getLabel } from '../../../utils';
import type { OperationHoursActionProps } from './OperationHoursAction.types';

export const OperationHoursAction: FC<OperationHoursActionProps> = ({
  onClick,
  isOpen,
  color,
  locations = [],
}) => {
  const [preferredLocation] = usePreferredLocation();
  const selectedLocation = useMemo(
    () => locations[preferredLocation] ?? locations[0],
    [locations, preferredLocation]
  );

  if (!locations.length) return null;

  return (
    <button
      className={clsx([
        'flex',
        'items-center',
        'gap-8',
        'text-text-3-mob',
        'lg:text-text-3',
      ])}
      style={{ color }}
      onClick={onClick}
    >
      {getLabel(selectedLocation)}

      <Icon
        icon="chevronDown"
        height={16}
        className={clsx([
          'fill-text-primary',
          'transition-all',
          { 'rotate-180': isOpen },
        ])}
        fill={color}
      />
    </button>
  );
};
