export const FONT_WEIGHT_CLASS_MAP: Record<string, string> = {
  '100': 'font-thin',
  '200': 'font-extralight',
  '300': 'font-light',
  '400': 'font-normal',
  '500': 'font-medium',
  '600': 'font-semibold',
  '700': 'font-bold',
  '800': 'font-extrabold',
  '900': 'font-black',
};

export const getFontWeightClass = (fontWeight: number | string) => {
  return FONT_WEIGHT_CLASS_MAP[`${fontWeight}`] ?? FONT_WEIGHT_CLASS_MAP[400];
};
