import { getLabel } from '@/components/general/navbars/NavbarVersion1/utils';
import { useDrawers } from '@/hooks';
import { usePreferredLocation } from '@/nanostores/PreferredLocation/hooks';
import type {
  Location,
  NavbarVersion3NavbarSectionHoursActionButton,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { type FC, useMemo } from 'react';
import { IoLocationOutline } from 'react-icons/io5';

// const CLOSES_OPENS_TEXT: Record<StatusLabel, string> = {
//   'Closed Now': 'Opens',
//   'Open Now': 'Closes at',
//   'Opening Soon': 'Opens at',
//   'Closing Soon': 'Closes at',
//   'Closed Today': 'Opens',
//   'Not Open Yet': 'Opens at',
//   'Open 24 hours': '',
// };

export type HoursActionButtonProps =
  NavbarVersion3NavbarSectionHoursActionButton['value'] & {
    locations?: Location[];
    color?: string;
  };

export const HoursActionButton: FC<HoursActionButtonProps> = ({
  color,
  showBookingCta,
  showCallCta,
  locations = [],
}) => {
  const [preferredLocation] = usePreferredLocation();
  const { setDrawerState } = useDrawers();
  const selectedLocation = useMemo(
    () => locations[preferredLocation] ?? locations[0],
    [locations, preferredLocation]
  );
  const statusText = getLabel(selectedLocation);
  // const closesOpensText = CLOSES_OPENS_TEXT[statusText];
  // const closesOpensAtText = getOpensClosesText(selectedLocation);

  if (!locations.length) return null;

  return (
    <button
      className={clsx([
        'flex',
        'items-center',
        'gap-8',
        'whitespace-nowrap',
        'text-text-3-mob',
        'lg:text-text-3',
        'lg:p-12',
      ])}
      style={{ color }}
      onClick={() =>
        setDrawerState('operationHours', {
          isOpen: true,
          showBookingCta,
          showCallCta,
        })
      }
    >
      <IoLocationOutline size={28} className={clsx(['lg:hidden'])} />

      <div className={clsx(['flex', 'flex-col', 'text-left'])}>
        <span className="font-bold">{selectedLocation.name}</span>

        <div>
          <span>
            {/* {getLabel(selectedLocation)} • {closesOpensText} {closesOpensAtText} */}
            {statusText}
          </span>
        </div>
      </div>
    </button>
  );
};
