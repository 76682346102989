import { dayjs } from '@/utils/dayjs';

import type { GetLabel } from './getLabel.types';

export const getLabel: GetLabel = (location) => {
  const { timezone, regularOpeningHours } = location;
  const currentDayjs = dayjs().tz(timezone);
  const currentDayOfWeek = currentDayjs.day(); // Get numerical day of the week (0-6)
  const currentDayOperationHours = regularOpeningHours.find(
    (openingHoursPeriod) =>
      openingHoursPeriod.day ===
      (currentDayOfWeek === 0 ? '7' : currentDayOfWeek.toString()) // Adjust for Sunday
  );

  if (!currentDayOperationHours) return 'Closed Today';

  const { close, open } = currentDayOperationHours;
  const openDayjs = dayjs
    .tz(open, timezone)
    .set('year', currentDayjs.year())
    .set('month', currentDayjs.month())
    .set('date', currentDayjs.date());
  const closeDayjs = dayjs
    .tz(close, timezone)
    .set('year', currentDayjs.year())
    .set('month', currentDayjs.month())
    .set('date', currentDayjs.date());

  // If close is before open, it means it closes the next day.
  if (openDayjs > closeDayjs) closeDayjs.add(1, 'day');

  // If both open and close are 00:00, it means it's open 24 hours.
  const isOpen24Hours =
    openDayjs.isSame(closeDayjs) && openDayjs.isSame(openDayjs.startOf('day'));

  if (isOpen24Hours) return 'Open 24 hours';

  if (
    openDayjs > currentDayjs &&
    openDayjs.subtract(30, 'minutes') < currentDayjs
  )
    return 'Opening Soon';

  if (
    closeDayjs > currentDayjs &&
    closeDayjs.subtract(30, 'minutes') < currentDayjs
  )
    return 'Closing Soon';

  if (currentDayjs < openDayjs) return 'Not Open Yet';

  if (currentDayjs >= closeDayjs) return 'Closed Now';

  return 'Open Now';
};
