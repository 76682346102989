import { LOGO_MOTION_PROPS } from '@/components/general/navbars/NavbarVersion1/NavbarVersion1.constants';
import type { NavbarVersion3NavbarSectionImageItem } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';

export type NavbarImageItemProps =
  NavbarVersion3NavbarSectionImageItem['value'];

export const NavbarImageItem: FC<NavbarImageItemProps> = ({
  href,
  image,
  clickAnimation,
  hoverAnimation,
}) => {
  if (!image) return null;

  const animationOnHover =
    LOGO_MOTION_PROPS[hoverAnimation || 'none'] ?? LOGO_MOTION_PROPS.none;
  const animationOnClick =
    LOGO_MOTION_PROPS[clickAnimation || 'none'] ?? LOGO_MOTION_PROPS.none;

  const imageComponent = (
    <motion.img
      whileHover={animationOnHover}
      whileTap={animationOnClick}
      initial={{ rotate: 0 }}
      src={image.src}
      alt={image.alt}
      className={clsx([
        'object-contain',
        // hideLogo?.mobile && 'hidden',
        // hideLogo?.desktop && 'lg:hidden',
        // !hideLogo?.desktop && 'lg:block',
      ])}
    />
  );

  return (
    <div className={clsx(['flex', 'w-full', 'justify-center', 'items-center'])}>
      {!!href?.trim().length && (
        <a
          href="/"
          className={clsx([
            'flex',
            'w-full',
            'items-center',
            'gap-8',
            'text-22',
            'uppercase',
            'lg:gap-20',
            // sizeClass,
          ])}
        >
          {imageComponent}
        </a>
      )}

      {!href?.trim().length && imageComponent}
    </div>
  );
};
