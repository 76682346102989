import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { Icon } from '@/components/general/Icon';
import { dropdown } from '@/constants/motionProps';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { CSSProperties, FC } from 'react';
import { useRef } from 'react';

import { HomeButton } from '..';
import {
  BookingAction,
  OperationHoursAction,
  ProfileAction,
  SearchAction,
} from '../actions';
import { CartAction } from '../actions/CartAction';
import type { DesktopBarProps } from './DesktopBar.types';

export const DesktopBar: FC<DesktopBarProps> = ({
  actions,
  background,
  handleOpen,
  handleSubDropdown,
  isOpen,
  isOperationHoursDrawerOpen,
  logo,
  logoAnimation,
  logoPosition,
  logoVariant,
  logotypePosition,
  logotypeVariant,
  logotypeFontWeight,
  hideLogo,
  hideLogotype,
  name,
  padding,
  size,
  subDropdown,
  toggleCartDrawer,
  toggleOperationHoursDrawer,
  toggleBookingDrawer,
  toggleSearchModal,
  currency = 'CAD',
  links = [],
  locations = [],
}) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const navSizeClass = size === 'sm' ? 'h-44' : 'h-100';
  const paddingStyles: CSSProperties = {
    paddingTop: padding?.vertical ? `${padding.vertical}px` : undefined,
    paddingBottom: padding?.vertical ? `${padding.vertical}px` : undefined,
    paddingLeft: padding?.horizontal ? `${padding.horizontal}px` : undefined,
    paddingRight: padding?.horizontal ? `${padding.horizontal}px` : undefined,
  };
  const logoSrc = logoVariant === 'dark' ? logo?.dark?.src : logo?.light?.src;
  const linksLevel2 = links[isOpen]?.links ?? [];
  const isLinksLevel2Open = linksLevel2.length && isOpen !== -1;
  const linksLevel3 = links[isOpen]?.links?.[subDropdown]?.links ?? [];
  const isLinksLevel3Open =
    linksLevel3.length && isLinksLevel2Open && subDropdown !== -1;
  const promos = links[isOpen]?.links?.[subDropdown]?.promos ?? [];

  return (
    <BackgroundWrapper background={background}>
      <div
        ref={navbarRef}
        className={clsx(['relative', 'z-10', 'hidden', 'w-full', 'lg:block'])}
      >
        <div
          className={clsx([
            navSizeClass,
            'relative',
            'z-10',
            'size-full',
            'overflow-hidden',
            'px-16',
            'text-text-primary',
          ])}
          style={paddingStyles}
        >
          <div
            className={clsx([
              'relative',
              'z-10',
              'flex',
              'h-full',
              'items-center',
              'justify-between',
              'lg:mx-auto',
              'lg:max-w-lg',
            ])}
          >
            <ul
              className={clsx([
                'flex',
                'h-full',
                'items-center',
                'gap-24',
                'list-none',
                'm-0',
                'p-0',
              ])}
            >
              <HomeButton
                img={logoPosition === 'left' ? logoSrc : undefined}
                title={logotypePosition === 'left' ? name : undefined}
                titleVariant={logotypeVariant}
                hideLogo={hideLogo}
                hideLogotype={hideLogotype}
                titleWeight={logotypeFontWeight}
                size={size}
                logoAnimation={logoAnimation}
              />

              {links?.map((link, index) => {
                const isLink = !link.hasChildren && !!link.href;
                const className = clsx(['flex', 'items-center', 'gap-8']);
                const content = isLink ? (
                  <a href={link.href} className={className}>
                    {link.label}
                  </a>
                ) : (
                  <button
                    className={className}
                    onClick={() => handleOpen(index, link.links)}
                  >
                    {link.label}

                    {!!link.links?.length && (
                      <Icon
                        icon="chevronDown"
                        width={12}
                        className={clsx([
                          'link-level-1-chevron-icon',
                          'transition',
                          'group-hover:fill-accent-1',
                          isOpen !== index && ['fill-text-primary', 'rotate-0'],
                          isOpen === index && [
                            'link-level-1-chevron-icon-active',
                            'fill-accent-1',
                            'rotate-180',
                          ],
                        ])}
                      />
                    )}
                  </button>
                );

                return (
                  <li
                    key={index}
                    className={clsx([
                      'link-level-1',
                      'group',
                      'cursor-pointer',
                      'text-14',
                      'hover:text-accent-1',
                      'border-b',
                      'border-transparent',
                      'hover:border-accent-1',
                      'transition',
                      { 'text-text-primary': isOpen !== index },
                      isOpen === index && [
                        'link-level-1-active',
                        'border-b',
                        'border-accent-1',
                        'text-accent-1',
                      ],
                    ])}
                  >
                    {content}
                  </li>
                );
              })}
            </ul>

            <HomeButton
              img={logoPosition === 'center' ? logoSrc : undefined}
              title={logotypePosition === 'center' ? name : undefined}
              titleVariant={logotypeVariant}
              hideLogo={hideLogo}
              hideLogotype={hideLogotype}
              titleWeight={logotypeFontWeight}
              size={size}
              logoAnimation={logoAnimation}
            />

            {!!actions?.desktop?.length && (
              <ul className={clsx(['flex', 'gap-32', 'items-center'])}>
                {actions.desktop.map((action, index) => {
                  if (!action.visible) return null;

                  let component: JSX.Element | null = null;

                  switch (action.action) {
                    case 'hours': {
                      const { color } = action;

                      component = (
                        <OperationHoursAction
                          isOpen={isOperationHoursDrawerOpen}
                          onClick={toggleOperationHoursDrawer}
                          locations={locations}
                          color={color}
                        />
                      );

                      break;
                    }
                    case 'cart': {
                      const { color } = action;

                      component = (
                        <CartAction
                          onClick={toggleCartDrawer}
                          currency={currency}
                          color={color}
                        />
                      );

                      break;
                    }
                    case 'profile': {
                      const { color } = action;

                      component = (
                        <ProfileAction
                          color={color}
                          onClick={() => {
                            window.location.href = '/profile';
                          }}
                        />
                      );

                      break;
                    }
                    case 'search': {
                      const { color } = action;

                      component = (
                        <SearchAction
                          color={color}
                          onClick={toggleSearchModal}
                        />
                      );

                      break;
                    }
                    case 'booking': {
                      const { color } = action;

                      component = (
                        <BookingAction
                          onClick={toggleBookingDrawer}
                          color={color}
                        />
                      );

                      break;
                    }
                    default:
                      component = null;
                  }

                  if (!component) return null;

                  return (
                    <li
                      key={index}
                      className={clsx([
                        'navbar-action-item',
                        'flex',
                        'items-center',
                      ])}
                    >
                      {component}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <motion.div
          initial="closed"
          variants={dropdown}
          animate={isLinksLevel2Open ? 'open' : 'closed'}
          className={clsx([
            'z-[5]',
            'w-full',
            'bg-background-secondary/80',
            'px-16',
            'text-text-contrast',
            'backdrop-blur-md',
          ])}
          // style={paddingStyles}
        >
          {!!linksLevel2?.length && (
            <div
              className={clsx([
                'flex',
                'w-full',
                'flex-col',
                'gap-20',
                'py-40',
                'lg:mx-auto',
                'lg:max-w-lg',
                'lg:flex-row',
                'lg:py-20',
              ])}
            >
              {linksLevel2.map((link, index) => {
                const isLink = !link.hasChildren && !!link.href;
                const className = clsx([
                  'link-level-2',
                  'group',
                  'flex',
                  'cursor-pointer',
                  'items-center',
                  'gap-8',
                  'text-start',
                  'text-14',
                  'hover:text-accent-1',
                  'border-b',
                  'border-transparent',
                  'hover:border-accent-1',
                  'transition',
                  { 'text-text-primary': subDropdown !== index },
                  subDropdown === index && [
                    'link-level-2-active',
                    'border-b',
                    'border-accent-1',
                    'text-accent-1',
                  ],
                ]);

                if (isLink)
                  return (
                    <a key={index} href={link.href} className={className}>
                      {link.label}
                    </a>
                  );

                return (
                  <button
                    key={index}
                    className={className}
                    onClick={() => handleSubDropdown(index)}
                  >
                    {link.label}

                    {link.links?.length && (
                      <Icon
                        icon="chevronDown"
                        width={12}
                        className={clsx([
                          'link-level-2-chevron-icon',
                          'transition',
                          'group-hover:fill-accent-1',
                          subDropdown !== index && [
                            'fill-text-primary',
                            'rotate-0',
                          ],
                          subDropdown === index && [
                            'link-level-2-chevron-icon-active',
                            'fill-accent-1',
                            'rotate-180',
                          ],
                        ])}
                      />
                    )}
                  </button>
                );
              })}
            </div>
          )}
        </motion.div>

        <motion.div
          initial="closed"
          variants={dropdown}
          animate={isLinksLevel3Open ? 'open' : 'closed'}
          className={clsx([
            'z-[5]',
            'w-full',
            'bg-white/80',
            'px-16',
            'text-text-contrast',
            'backdrop-blur-md',
          ])}
        >
          <div
            className={clsx([
              'grid',
              'w-full',
              'grid-cols-3',
              'gap-40',
              'lg:mx-auto',
              'lg:max-w-lg',
              'lg:py-40',
            ])}
          >
            {!!linksLevel3?.length && (
              <div className="flex flex-col gap-20">
                {linksLevel3.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    className={clsx([
                      'link-level-3',
                      'cursor-pointer',
                      'text-text-primary',
                      'transition',
                      'border-b',
                      'border-transparent',
                      'hover:border-accent-1',
                      'hover:text-accent-1',
                      'lg:text-14',
                      'w-fit',
                    ])}
                  >
                    {link.label}
                  </a>
                ))}
              </div>
            )}

            {promos?.map((promo, index) => {
              const className = clsx([
                'flex',
                'w-full',
                'cursor-pointer',
                'flex-col',
                'gap-12',
                'text-accent-1',
                'transition',
                'hover:text-text-primary',
                'lg:text-14',
              ]);
              const isLink = !!promo.href?.trim().length;
              const content = (
                <>
                  <img
                    src={promo.image?.src}
                    alt={promo.image?.alt}
                    loading="lazy"
                    className="h-[320px] w-full object-cover object-center"
                  />

                  {promo.caption}
                </>
              );

              if (isLink)
                return (
                  <a key={index} href={promo.href} className={className}>
                    {content}
                  </a>
                );

              return (
                <div key={index} className={className}>
                  {content}
                </div>
              );
            })}
          </div>
        </motion.div>
      </div>
    </BackgroundWrapper>
  );
};
