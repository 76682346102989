import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import clsx from 'clsx';
import type { FC } from 'react';

import { ITEM_ALIGN_CLASS_MAP } from './TopBar.constants';
import type { TopBarProps } from './TopBar.types';

export const TopBar: FC<TopBarProps> = ({ background, itemAlign, items }) => {
  const itemAlignClass = ITEM_ALIGN_CLASS_MAP[itemAlign];

  return (
    <BackgroundWrapper background={background}>
      <div
        className={clsx([
          'h-30',
          'w-full',
          'bg-primary',
          'px-40',
          'text-text-contrast',
          'lg:h-44',
          'lg:px-20',
        ])}
      >
        <div
          className={clsx([
            'flex',
            'h-full',
            'items-center',
            'justify-start',
            'gap-40',
            'text-14',
            'lg:mx-auto',
            'lg:max-w-lg',
            'lg:gap-24',
            'lg:text-20',
            itemAlignClass,
          ])}
        >
          {items.map(({ label, href, target }, index) => {
            if (!label?.trim().length) return null;

            if (href?.trim().length)
              return (
                <a href={href} target={target} key={index}>
                  {label}
                </a>
              );

            return <span key={index}>{label}</span>;
          })}
        </div>
      </div>
    </BackgroundWrapper>
  );
};
