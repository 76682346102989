import { useDrawers, useGoogleAnalytics } from '@/hooks';
import clsx from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { BsCalendar2Event as BsCalendar2EventIcon } from 'react-icons/bs';

export type BookingActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
};

export const BookingActionButton: FC<BookingActionButtonProps> = forwardRef(
  (
    {
      className,
      color,
      'aria-label': ariaLabel = 'Booking',
      children = (
        <BsCalendar2EventIcon
          color={color}
          className={clsx(['text-primary', 'text-[25px]', 'lg:text-[29px]'])}
        />
      ),
      ...restProps
    },
    ref
  ) => {
    const { setDrawerState } = useDrawers();
    const ga = useGoogleAnalytics();

    const handleButtonClick = () => {
      ga.events.clickActionBook({
        text: typeof children === 'string' ? children : undefined,
        url: window.location.href,
      });
      setDrawerState('booking', { isOpen: true });
    };

    return (
      <button
        {...restProps}
        ref={ref}
        className={clsx([
          'navbar-profile-button',
          'group',
          'relative',
          'flex',
          'aspect-square',
          'w-[32px]',
          'items-center',
          'justify-center',
          className,
        ])}
        aria-label={ariaLabel}
        onClick={handleButtonClick}
      >
        <span className="navbar-profile-button-child">{children}</span>
      </button>
    );
  }
);
