import { useDrawers } from '@/hooks';
import clsx from 'clsx';
import { forwardRef } from 'react';
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import { BsSearch as BsSearchIcon } from 'react-icons/bs';

export type SearchActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  color?: string;
};

export const SearchActionButton: FC<SearchActionButtonProps> = forwardRef(
  (
    {
      className,
      color,
      'aria-label': ariaLabel = 'Search',
      children = (
        <BsSearchIcon
          color={color}
          className={clsx(['text-primary', 'text-[25px]', 'lg:text-[29px]'])}
        />
      ),
      ...restProps
    },
    ref
  ) => {
    const { setDrawerState } = useDrawers();

    return (
      <button
        {...restProps}
        ref={ref}
        className={clsx([
          'navbar-search-button',
          'group',
          'relative',
          'flex',
          'aspect-square',
          'w-[32px]',
          'items-center',
          'justify-center',
          className,
        ])}
        aria-label={ariaLabel}
        onClick={() => setDrawerState('search', { isOpen: true })}
      >
        <span className="navbar-search-button-child">{children}</span>
      </button>
    );
  }
);
