import { useGoogleAnalytics } from '@/hooks';
import { useCart } from '@/hooks/useCart';
import { useBookingDrawer } from '@/nanostores/BookingDrawer/hooks';
import { useOperationHoursDrawer } from '@/nanostores/OperationHoursDrawer/hooks';
import { usePreferredLocation } from '@/nanostores/PreferredLocation/hooks';
import { type FC, useCallback, useMemo, useState } from 'react';

import { SearchModal } from '../../SearchModal/SearchModal';
import type { NavbarProps } from './NavbarVersion1.types';
import { CartDrawer, TopBar } from './components';
import { DesktopBar } from './components/DesktopBar';
import { MobileBar } from './components/MobileBar';
import { BookingDrawer } from './components/drawers/BookingDrawer';
import { OperationHoursDrawer } from './components/drawers/OperationHoursDrawer';

export const NavbarVersion1: FC<NavbarProps> = (props) => {
  const {
    backHref,
    badgeConfigs,
    topBars,
    actions,
    position = 'fixed',
    locations = [],
  } = props;
  const [preferredLocation] = usePreferredLocation();
  const ga = useGoogleAnalytics();
  const selectedLocation = useMemo(
    () => locations[preferredLocation] ?? locations[0],
    [locations, preferredLocation]
  );
  const { currency } = selectedLocation ?? {};
  const [isOpen, setIsOpen] = useState(-1);
  const [subDropdown, setSubDropdown] = useState(-1);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { toggleCartDrawer } = useCart(currency);
  const [isBookingDrawerOpen, setIsBookingDrawerOpen] = useBookingDrawer();
  const [isOperationHoursDrawerOpen, setIsOperationHoursDrawerOpen] =
    useOperationHoursDrawer();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const hoursActionMobile = actions.mobile.find(
    (action) => action.action === 'hours'
  );
  const hoursActionDesktop = actions.desktop.find(
    (action) => action.action === 'hours'
  );

  const toggleIsSearchModalOpen = useCallback(
    () => setIsSearchModalOpen(!isSearchModalOpen),
    [isSearchModalOpen, setIsSearchModalOpen]
  );

  const toggleIsOperationHoursOpen = useCallback(
    () => setIsOperationHoursDrawerOpen(!isOperationHoursDrawerOpen),
    [isOperationHoursDrawerOpen, setIsOperationHoursDrawerOpen]
  );

  const toggleIsBookingDrawerOpen = useCallback(() => {
    ga.events.clickActionBook({ url: window.location.href });
    setIsBookingDrawerOpen(!isBookingDrawerOpen);
  }, [ga.events, isBookingDrawerOpen, setIsBookingDrawerOpen]);

  const handleOpen = (i: number, dropdowns: any) => {
    if (dropdowns) {
      if (isOpen === i) {
        setIsOpen(-1);
      } else {
        setIsOpen(i);
      }
    }
  };

  const handleSubDropdown = (i: number) => {
    if (subDropdown === i) {
      setSubDropdown(-1);
    } else {
      setSubDropdown(i);
    }
  };

  const toggleMobile = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true);
    }
  };

  return (
    <nav className={`${position} navbar left-0 top-0 z-[500] w-full`}>
      {topBars?.map(
        ({ background: topBarBackground, itemAlign, items }, index) => {
          return (
            <TopBar
              key={index}
              background={topBarBackground}
              itemAlign={itemAlign}
              items={items}
            />
          );
        }
      )}

      <DesktopBar
        {...props}
        isOpen={isOpen}
        handleOpen={handleOpen}
        subDropdown={subDropdown}
        handleSubDropdown={handleSubDropdown}
        toggleCartDrawer={toggleCartDrawer}
        toggleOperationHoursDrawer={toggleIsOperationHoursOpen}
        isOperationHoursDrawerOpen={isOperationHoursDrawerOpen}
        toggleBookingDrawer={toggleIsBookingDrawerOpen}
        toggleSearchModal={toggleIsSearchModalOpen}
      />
      <MobileBar
        {...props}
        isOpen={isOpen}
        handleOpen={handleOpen}
        subDropdown={subDropdown}
        handleSubDropdown={handleSubDropdown}
        toggleCartDrawer={toggleCartDrawer}
        mobileOpen={mobileOpen}
        toggleMobile={toggleMobile}
        toggleOperationHoursDrawer={toggleIsOperationHoursOpen}
        isOperationHoursDrawerOpen={isOperationHoursDrawerOpen}
        toggleBookingDrawer={toggleIsBookingDrawerOpen}
        toggleSearchModal={toggleIsSearchModalOpen}
      />

      <CartDrawer
        currency={currency}
        backHref={backHref}
        badgeConfigs={badgeConfigs}
      />
      <OperationHoursDrawer
        open={isOperationHoursDrawerOpen}
        locations={locations}
        onClose={toggleIsOperationHoursOpen}
        onBookConsultClick={toggleIsBookingDrawerOpen}
        showBookingCtaDesktop={hoursActionDesktop?.showBookingCta}
        showBookingCtaMobile={hoursActionMobile?.showBookingCta}
        showCallCtaDesktop={hoursActionDesktop?.showCallCta}
        showCallCtaMobile={hoursActionMobile?.showCallCta}
      />
      <BookingDrawer
        isOpen={isBookingDrawerOpen}
        onClose={toggleIsBookingDrawerOpen}
        locations={locations}
      />
      <SearchModal open={isSearchModalOpen} onClose={toggleIsSearchModalOpen} />
    </nav>
  );
};
