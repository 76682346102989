import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import type { NavbarVersion2BannerSection } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

export type NavbarBannerSectionProps = NavbarVersion2BannerSection['value'];

export const NavbarBannerSection: FC<NavbarBannerSectionProps> = ({
  background,
  content,
}) => {
  return (
    <BackgroundWrapper
      background={background}
      wrapperProps={{
        className: clsx([
          'w-full',
          'bg-primary',
          'text-text-contrast',
          'text-text-3-mob',
          'lg:text-text-3',
          'px-20',
          'py-[4px]',
          'lg:py-8',
        ]),
      }}
    >
      <div
        className={clsx(['w-full', 'max-w-lg', 'mx-auto'])}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </BackgroundWrapper>
  );
};
