import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import type {
  LinkGroupLinkLevel1,
  LinkGroupLinkLevel2,
  NavbarVersion3NavbarSectionLinkGroupItem,
  TextColor,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { type FC, useCallback } from 'react';
import { useIsClient, useLocalStorage } from 'usehooks-ts';

import { NavbarLinkItem } from './components';

export type NavbarLinkGroupItemProps =
  NavbarVersion3NavbarSectionLinkGroupItem['value'] & {
    activeTextColor?: string;
    defaultActiveTextColor?: TextColor;
    defaultInactiveTextColor?: TextColor;
    inactiveTextColor?: string;
    onClick?: (linkGroupLevel1: LinkGroupLinkLevel1) => void;
  };

export const NavbarLinkGroupItem: FC<NavbarLinkGroupItemProps> = ({
  activeTextColor,
  defaultActiveTextColor,
  defaultInactiveTextColor,
  inactiveTextColor,
  linkGroup,
}) => {
  const isClient = useIsClient();
  const [linkGroupLevel1, setLinkGroupLevel1] =
    useLocalStorage<LinkGroupLinkLevel1 | null>(
      'navbarVersion3LinkGroupLevel1',
      null
    );
  const [linkGroupLevel2, setLinkGroupLevel2] =
    useLocalStorage<LinkGroupLinkLevel2 | null>(
      'navbarVersion3LinkGroupLevel2',
      null
    );
  const [isLinkGroupLevel2Expanded, setIsLinkGroupLevel2Expanded] =
    useLocalStorage<boolean>('navbarVersion3IsLinkGroupLevel2Expanded', false);
  const [isLinkGroupLevel3Expanded, setIsLinkGroupLevel3Expanded] =
    useLocalStorage<boolean>('navbarVersion3IsLinkGroupLevel3Expanded', false);

  const handleLinkGroupLevel1Click = useCallback(
    (newLinkGroupLevel1: LinkGroupLinkLevel1) => {
      const isSameLinkGroupLevel1 =
        JSON.stringify(linkGroupLevel1) === JSON.stringify(newLinkGroupLevel1);

      if (isSameLinkGroupLevel1) {
        setIsLinkGroupLevel2Expanded(!isLinkGroupLevel2Expanded);
        setIsLinkGroupLevel3Expanded(false);
      } else {
        setIsLinkGroupLevel2Expanded(true);
        setIsLinkGroupLevel3Expanded(false);
        setLinkGroupLevel1(newLinkGroupLevel1);
      }
    },
    [
      isLinkGroupLevel2Expanded,
      linkGroupLevel1,
      setIsLinkGroupLevel2Expanded,
      setIsLinkGroupLevel3Expanded,
      setLinkGroupLevel1,
    ]
  );

  const handleLinkGroupLevel2Click = useCallback(
    (newLinkGroupLevel2: LinkGroupLinkLevel2) => {
      const isSameLinkGroupLevel2 =
        JSON.stringify(linkGroupLevel2) === JSON.stringify(newLinkGroupLevel2);

      if (isSameLinkGroupLevel2) {
        setIsLinkGroupLevel3Expanded(!isLinkGroupLevel3Expanded);
      } else {
        setIsLinkGroupLevel3Expanded(true);
        setLinkGroupLevel2(newLinkGroupLevel2);
      }
    },
    [
      isLinkGroupLevel3Expanded,
      linkGroupLevel2,
      setIsLinkGroupLevel3Expanded,
      setLinkGroupLevel2,
    ]
  );

  if (!linkGroup?.items.length) return null;

  const linkGroupLevel1Items = linkGroup.items.map((linkGroupItem) => ({
    ...linkGroupItem,
    defaultTextColor: linkGroup.defaultTextColor,
    textColor: linkGroup.textColor,
    defaultActiveTextColor: linkGroup.defaultActiveTextColor,
    activeTextColor: linkGroup.activeTextColor,
  })) satisfies (LinkGroupLinkLevel1 & {
    defaultTextColor?: TextColor;
    textColor?: string;
    defaultActiveTextColor?: TextColor;
    activeTextColor?: string;
  })[];

  return (
    <ul
      className={clsx([
        'navbar-v2-link-group-level-1',
        'navbar-v2-link-group-level-1-mobile',
        'list-none',
        'p-0',
        'm-0',
        'flex',
        'flex-col',
        'w-full',
        'transition-all',
        ['max-h-0', 'overflow-hidden'],
        ['max-h-screen', 'overflow-y-auto'],
      ])}
    >
      {linkGroupLevel1Items.map((linkGroupLevel1Item, linkGroupLevel1Index) => {
        const linkGroupLevel1ItemKey = `linkGroupLevel1Item-${linkGroupLevel1Index}`;

        return (
          <li key={linkGroupLevel1ItemKey}>
            <div
              className={clsx([
                'p-16',
                'border-t',
                'border-background-secondary',
              ])}
            >
              <NavbarLinkItem
                href={linkGroupLevel1Item.href || '#'}
                label={linkGroupLevel1Item.label}
                onClick={() => handleLinkGroupLevel1Click(linkGroupLevel1Item)}
                linkGroupLevel1={linkGroupLevel1Item}
                expanded={
                  isClient &&
                  JSON.stringify(linkGroupLevel1) ===
                    JSON.stringify(linkGroupLevel1Item) &&
                  isLinkGroupLevel2Expanded
                }
                defaultInactiveColor={
                  linkGroupLevel1Item.defaultTextColor ||
                  defaultInactiveTextColor
                }
                inactiveTextColor={
                  linkGroupLevel1Item.textColor || inactiveTextColor
                }
                defaultActiveColor={
                  linkGroupLevel1Item.defaultActiveTextColor ||
                  defaultActiveTextColor
                }
                activeTextColor={
                  linkGroupLevel1Item.activeTextColor || activeTextColor
                }
                fullWidth
              />
            </div>

            {/* Link group level 2 */}
            {!!linkGroupLevel1Item.children.length && (
              <ul
                className={clsx([
                  'navbar-v2-link-group-level-2',
                  'navbar-v2-link-group-level-2-mobile',
                  'list-none',
                  'p-0',
                  'm-0',
                  'flex',
                  'flex-col',
                  'w-full',
                  'transition-all',
                  !(
                    isClient &&
                    JSON.stringify(linkGroupLevel1) ===
                      JSON.stringify(linkGroupLevel1Item) &&
                    isLinkGroupLevel2Expanded
                  ) && ['max-h-0', 'overflow-hidden'],
                  isClient &&
                    JSON.stringify(linkGroupLevel2) ===
                      JSON.stringify(linkGroupLevel1Item) &&
                    isLinkGroupLevel3Expanded && [
                      'max-h-screen',
                      'overflow-y-auto',
                    ],
                ])}
              >
                {linkGroupLevel1Item.children.map(
                  (linkGroupLevel2Item, linkGroupLevel2Index) => {
                    const linkGroupLevel2ItemKey = `linkGroupLevel2Item-${linkGroupLevel2Index}`;

                    return (
                      <li key={linkGroupLevel2ItemKey}>
                        <BackgroundWrapper
                          background={linkGroupLevel1?.childBackground}
                          wrapperProps={{
                            className: clsx([
                              'p-16',
                              'pl-32',
                              'border-t',
                              'border-background-primary',
                              'bg-background-secondary',
                            ]),
                          }}
                        >
                          <NavbarLinkItem
                            href={linkGroupLevel2Item.href || '#'}
                            label={linkGroupLevel2Item.label}
                            hasChildren={!!linkGroupLevel2Item.children?.length}
                            onClick={() =>
                              handleLinkGroupLevel2Click(linkGroupLevel2Item)
                            }
                            expanded={
                              isClient &&
                              JSON.stringify(linkGroupLevel2) ===
                                JSON.stringify(linkGroupLevel2Item) &&
                              isLinkGroupLevel3Expanded
                            }
                            fontSize={12}
                            defaultInactiveColor={
                              linkGroupLevel1?.childDefaultTextColor
                            }
                            inactiveTextColor={linkGroupLevel1?.childTextColor}
                            defaultActiveColor={
                              linkGroupLevel1?.childDefaultActiveTextColor
                            }
                            activeTextColor={
                              linkGroupLevel1?.childActiveTextColor
                            }
                            fullWidth
                          />
                        </BackgroundWrapper>

                        {/* Link group level 3 */}
                        {!!linkGroupLevel2Item.children.length && (
                          <ul
                            className={clsx([
                              'navbar-v2-link-group-level-3',
                              'navbar-v2-link-group-level-3-mobile',
                              'list-none',
                              'p-0',
                              'm-0',
                              'flex',
                              'flex-col',
                              'w-full',
                              'transition-all',
                              !(
                                isClient &&
                                JSON.stringify(linkGroupLevel2) ===
                                  JSON.stringify(linkGroupLevel2Item) &&
                                isLinkGroupLevel3Expanded
                              ) && ['max-h-0', 'overflow-hidden'],
                              isClient &&
                                JSON.stringify(linkGroupLevel2) ===
                                  JSON.stringify(linkGroupLevel2Item) &&
                                isLinkGroupLevel3Expanded && [
                                  'max-h-screen',
                                  'overflow-y-auto',
                                ],
                            ])}
                          >
                            {linkGroupLevel2Item.children.map(
                              (linkGroupLevel3Item, linkGroupLevel3Index) => {
                                const linkGroupLevel3ItemKey = `linkGroupLevel3Item-${linkGroupLevel3Index}`;

                                return (
                                  <BackgroundWrapper
                                    key={linkGroupLevel3ItemKey}
                                    background={
                                      linkGroupLevel2?.childBackground
                                    }
                                    wrapperProps={{
                                      className: clsx([
                                        'p-16',
                                        'pl-48',
                                        'border-t',
                                        'border-background-secondary',
                                        'bg-background-secondary',
                                      ]),
                                    }}
                                  >
                                    <li>
                                      <NavbarLinkItem
                                        href={linkGroupLevel3Item.href || '#'}
                                        label={linkGroupLevel3Item.label}
                                        fontSize={12}
                                        defaultInactiveColor={
                                          linkGroupLevel2?.childDefaultTextColor
                                        }
                                        inactiveTextColor={
                                          linkGroupLevel2?.childTextColor
                                        }
                                        defaultActiveColor={
                                          linkGroupLevel2?.childDefaultActiveTextColor
                                        }
                                        activeTextColor={
                                          linkGroupLevel2?.childActiveTextColor
                                        }
                                        fullWidth
                                      />
                                    </li>
                                  </BackgroundWrapper>
                                );
                              }
                            )}
                          </ul>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};
