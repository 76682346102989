import type { NavbarVersion3NavbarSectionSpacerItem } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

export type NavbarSpacerItemProps =
  NavbarVersion3NavbarSectionSpacerItem['value'];

export const NavbarSpacerItem: FC<NavbarSpacerItemProps> = ({ height }) => {
  return <div className={clsx(['w-full'])} style={{ height }} />;
};
