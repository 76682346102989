import { getTextColorClass } from '@/utils/getTextColorClass';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import type { LinkGroupLinkLevel1, TextColor } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import {
  type CSSProperties,
  type FC,
  type MouseEventHandler,
  useState,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import tailwindConfig from 'tailwind.config';

import styles from './NavbarLinkItem.module.scss';
import './NavbarLinkItem.scss';

export type NavbarLinkItemProps = {
  activeTextColor?: string;
  defaultActiveColor?: TextColor;
  defaultInactiveColor?: TextColor;
  expanded?: boolean;
  fontSize?: number;
  fullWidth?: boolean;
  hasChildren?: boolean;
  href: string;
  inactiveTextColor?: string;
  label: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  linkGroupLevel1?: LinkGroupLinkLevel1;
};

export const NavbarLinkItem: FC<NavbarLinkItemProps> = ({
  activeTextColor,
  defaultActiveColor,
  expanded,
  fontSize,
  fullWidth,
  hasChildren,
  href,
  inactiveTextColor,
  label,
  onClick,
  linkGroupLevel1,
  defaultInactiveColor = 'primary',
}) => {
  const { children: linkGroupLevel2List } = linkGroupLevel1 || {};
  const [internalExpanded, setInternalExpanded] = useState(expanded);

  const hasChildrenOrChildLinks = !!(
    hasChildren || linkGroupLevel2List?.length
  );
  const inactiveColor =
    inactiveTextColor ||
    tailwindConfig.theme.extend.colors[
      getTextColorClass(
        defaultInactiveColor
      ) as keyof typeof tailwindConfig.theme.extend.colors
    ];
  const activeColor =
    activeTextColor ||
    (defaultActiveColor
      ? tailwindConfig.theme.extend.colors[
          getTextColorClass(
            defaultActiveColor
          ) as keyof typeof tailwindConfig.theme.extend.colors
        ]
      : tailwindConfig.theme.extend.colors['accent-1']);

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (event.detail === 2 || !hasChildrenOrChildLinks) return;

    event.preventDefault();
    onClick?.(event);
  };

  return (
    <>
      <a
        className={clsx([
          'text-text-3-mob',
          'lg:text-text-3',
          { 'w-full': fullWidth },
          { 'w-fit': !fullWidth },
        ])}
        style={
          {
            fontSize: fontSize ? `${fontSize}px` : undefined,
            '--inactive-color': inactiveColor,
            '--active-color': activeColor,
          } as CSSProperties
        }
        href={href}
        onClick={handleClick}
      >
        <div
          className={clsx([
            'group',
            'flex',
            'items-center',
            'gap-8',
            'transition',
            'border-b',
            'w-fit',
            { [styles.linkInactive]: !internalExpanded },
            { [styles.linkActive]: internalExpanded },
          ])}
        >
          {label}

          {!!hasChildrenOrChildLinks && (
            <BsChevronDown
              size={fontSize ? `${fontSize + 2}px` : undefined}
              className={clsx([
                'transition',
                { 'rotate-180': internalExpanded },
                'text-16',
                'lg:text-18',
              ])}
            />
          )}
        </div>
      </a>
    </>
  );
};
