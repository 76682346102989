import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { ImageInterestPointCard } from '@/components/pages/sections/Hero/components';
import type {
  LinkGroup,
  LinkGroupLinkLevel1,
  LinkGroupLinkLevel2,
  Location,
  NavbarVersion2NavbarSection,
  NavbarVersion2NavbarSectionCtaButtonItem,
  SiteType,
  TextColor,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import {
  HamburgerButton,
  HomeButton,
} from '../../../NavbarVersion1/components';
import {
  NavbarActionButtonsItem,
  NavbarCtaButtonItem,
  NavbarImageItem,
  NavbarLinkGroupItem,
  NavbarTextItem,
} from './navbarItems';
import { NavbarLinkItem } from './navbarItems/NavbarLinkGroupItem/components';

export type NavbarNavbarSectionProps = NavbarVersion2NavbarSection['value'] & {
  activeTextColor?: string;
  currency?: string;
  defaultActiveTextColor?: TextColor;
  defaultInactiveTextColor?: TextColor;
  inactiveTextColor?: string;
  locations?: Location[];
  site: SiteType<'homeDecor1'>;
};

export const NavbarNavbarSection: FC<NavbarNavbarSectionProps> = ({
  activeTextColor,
  background,
  childLinksDisplayStyle,
  defaultActiveTextColor,
  defaultInactiveTextColor,
  inactiveTextColor,
  items,
  mobile,
  numColumns,
  site,
  currency = 'CAD',
  locations = [],
}) => {
  const [linkGroupLevel1, setLinkGroupLevel1] =
    useState<LinkGroupLinkLevel1 | null>(null);
  const [linkGroupLevel2, setLinkGroupLevel2] =
    useState<LinkGroupLinkLevel2 | null>(null);
  const [isLinkGroupLevel2Expanded, setIsLinkGroupLevel2Expanded] =
    useState(false);
  const [isLinkGroupLevel3Expanded, setIsLinkGroupLevel3Expanded] =
    useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  // Filter action buttons
  const actionButtonsNavbarItems = useMemo(() => {
    return items.filter((item) => item.type === 'actionButtons');
  }, [items]);
  const ctaButtonNavbarItems = useMemo(() => {
    return items.filter((item) => item.type === 'ctaButton');
  }, [items]) as ({
    size: number;
  } & NavbarVersion2NavbarSectionCtaButtonItem)[];
  const mobileActionButtonsNavbarItems = useMemo(() => {
    return actionButtonsNavbarItems.flatMap((actionButtonsItem) => {
      if (actionButtonsItem.type !== 'actionButtons') return [];

      return actionButtonsItem.value.mobile;
    });
  }, [actionButtonsNavbarItems]);
  const mobileVisibleActionButtonsNavbarItems = useMemo(() => {
    return mobileActionButtonsNavbarItems.filter(
      (actionButton) => !!actionButton.visible
    );
  }, [mobileActionButtonsNavbarItems]);
  const mobileVisibleCtaButtonNavbarItems = useMemo(() => {
    return ctaButtonNavbarItems.filter((ctaButton) => {
      return !ctaButton.value.hideOnMobile;
    });
  }, [ctaButtonNavbarItems]);
  const [
    firstVisibleMobileActionButtonsNavbarItem,
    ...restVisibleMobileActionButtonsNavbarItems
  ] = mobileVisibleActionButtonsNavbarItems;
  const searchMobileActionButton = mobileVisibleActionButtonsNavbarItems.find(
    (actionButton) => actionButton.type === 'search'
  );
  const profileMobileActionButton = mobileVisibleActionButtonsNavbarItems.find(
    (actionButton) => actionButton.type === 'profile'
  );
  // Filter link groups
  const linkGroups = items.reduce((prev, item) => {
    if (item.type !== 'linkGroup') return prev;
    if (!item.value.linkGroup) return prev;

    return [...prev, item.value.linkGroup];
  }, [] as LinkGroup[]);
  const linkGroupLevel1Items = linkGroups.flatMap(
    (linkGroup) =>
      linkGroup.items.map((linkGroupItem) => ({
        ...linkGroupItem,
        defaultTextColor: linkGroup.defaultTextColor,
        textColor: linkGroup.textColor,
        defaultActiveTextColor: linkGroup.defaultActiveTextColor,
        activeTextColor: linkGroup.activeTextColor,
      })) satisfies (LinkGroupLinkLevel1 & {
        defaultTextColor?: TextColor;
        textColor?: string;
        defaultActiveTextColor?: TextColor;
        activeTextColor?: string;
      })[]
  );

  const handleHamburgerMenuClick = () => {
    setIsHamburgerMenuOpen((prev) => !prev);
    setIsLinkGroupLevel2Expanded(false);
    setIsLinkGroupLevel3Expanded(false);
  };

  const handleLinkGroupLevel1Click = (
    newLinkGroupLevel1: LinkGroupLinkLevel1
  ) => {
    const isSameLinkGroupLevel1 =
      JSON.stringify(linkGroupLevel1) === JSON.stringify(newLinkGroupLevel1);

    if (isSameLinkGroupLevel1) {
      setIsLinkGroupLevel2Expanded((prev) => !prev);
      setIsLinkGroupLevel3Expanded(false);
    } else {
      setIsLinkGroupLevel2Expanded(true);
      setIsLinkGroupLevel3Expanded(false);
      setLinkGroupLevel1(newLinkGroupLevel1);
    }
  };

  const handleLinkGroupLevel2Click = (
    newLinkGroupLevel2: LinkGroupLinkLevel2
  ) => {
    const isSameLinkGroupLevel2 =
      JSON.stringify(linkGroupLevel2) === JSON.stringify(newLinkGroupLevel2);

    if (isSameLinkGroupLevel2) {
      setIsLinkGroupLevel3Expanded((prev) => !prev);
    } else {
      setIsLinkGroupLevel3Expanded(true);
      setLinkGroupLevel2(newLinkGroupLevel2);
    }
  };

  return (
    <BackgroundWrapper background={background}>
      <nav className={clsx(['flex', 'justify-center'])}>
        {/* Desktop */}
        <div
          className={clsx([
            'flex-col',
            'w-full',
            'items-center',
            'hidden',
            'lg:flex',
          ])}
        >
          {/* Navbar items */}
          <div
            className={clsx([
              'grid',
              'max-w-lg',
              'auto-rows-max',
              'w-full',
              'px-[15px]',
              'py-8',
              'lg:py-18',
              '[&>*]:flex',
              '[&>*]:items-center',
            ])}
            style={{
              gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
            }}
          >
            {items.map((navbarItem, index) => {
              const key = `${navbarItem.type}-${index}`;
              const gridColumn = `span ${navbarItem.size}`;
              let child: JSX.Element | null = null;

              switch (navbarItem.type) {
                case 'actionButtons': {
                  if (navbarItem.value.desktop.length)
                    child = (
                      <NavbarActionButtonsItem
                        currency={currency}
                        locations={locations}
                        actionButtons={navbarItem.value.desktop}
                        {...navbarItem.value}
                      />
                    );

                  break;
                }
                case 'ctaButton': {
                  if (!navbarItem.value.hideOnDesktop)
                    child = <NavbarCtaButtonItem {...navbarItem.value} />;

                  break;
                }
                case 'image': {
                  if (navbarItem.value.image?.src)
                    child = <NavbarImageItem {...navbarItem.value} />;

                  break;
                }
                case 'linkGroup': {
                  if (navbarItem.value.linkGroup?.items.length)
                    child = (
                      <NavbarLinkGroupItem
                        childLinksDisplayStyle={childLinksDisplayStyle}
                        onClick={(newLinkGroupLevel1) =>
                          handleLinkGroupLevel1Click(newLinkGroupLevel1)
                        }
                        {...navbarItem.value}
                      />
                    );

                  break;
                }
                case 'spacer': {
                  child = <></>;

                  break;
                }
                case 'text': {
                  child = <NavbarTextItem {...navbarItem.value} />;

                  break;
                }
                default: {
                  break;
                }
              }

              if (!child) return null;

              return (
                <div key={key} style={{ gridColumn }}>
                  {child}
                </div>
              );
            })}
          </div>

          {childLinksDisplayStyle !== 'dropdown' && (
            <>
              {/* Link group level 2 */}
              <BackgroundWrapper
                background={linkGroupLevel1?.childBackground}
                wrapperProps={{
                  className: clsx([
                    'flex',
                    'justify-center',
                    'w-full',
                    'transition-all',
                    !isLinkGroupLevel2Expanded && [
                      'max-h-0',
                      'overflow-hidden',
                    ],
                    !!isLinkGroupLevel2Expanded && [
                      'max-h-screen',
                      'overflow-y-auto',
                    ],
                  ]),
                }}
                containerProps={{
                  className: clsx(['w-full', 'max-w-lg']),
                }}
              >
                <div
                  className={clsx([
                    'w-full',
                    ' max-w-lg',
                    'px-[27px]',
                    'py-8',
                    'lg:py-18',
                    linkGroupLevel1?.promos.length && [
                      'grid',
                      'grid-cols-3',
                      'gap-24',
                    ],
                  ])}
                >
                  <ul
                    className={clsx([
                      'navbar-v2-link-group-level-2',
                      'navbar-v2-link-group-level-2-desktop',
                      'w-full',
                      'flex',
                      'whitespace-nowrap',
                      'flex-wrap',
                      'list-none',
                      'p-0',
                      'm-0',
                      {
                        'gap-24': !(
                          linkGroupLevel1?.promos.length ||
                          childLinksDisplayStyle === 'column'
                        ),
                      },
                      {
                        'gap-10':
                          linkGroupLevel1?.promos.length ||
                          childLinksDisplayStyle === 'column',
                      },
                      {
                        'flex-col':
                          linkGroupLevel1?.promos.length ||
                          childLinksDisplayStyle === 'column',
                      },
                    ])}
                  >
                    {linkGroupLevel1?.children.map((level2Item, index) => {
                      const level2ItemKey = `${level2Item.label}-${index}`;

                      return (
                        <li key={level2ItemKey}>
                          <NavbarLinkItem
                            href={level2Item.href || '#'}
                            label={level2Item.label}
                            hasChildren={!!level2Item.children?.length}
                            onClick={() =>
                              handleLinkGroupLevel2Click(level2Item)
                            }
                            defaultInactiveColor={
                              linkGroupLevel1.childDefaultTextColor
                            }
                            inactiveTextColor={linkGroupLevel1.childTextColor}
                            defaultActiveColor={
                              linkGroupLevel1.childDefaultActiveTextColor
                            }
                            activeTextColor={
                              linkGroupLevel1.childActiveTextColor
                            }
                            expanded={
                              JSON.stringify(linkGroupLevel2) ===
                                JSON.stringify(level2Item) &&
                              isLinkGroupLevel3Expanded
                            }
                          />
                        </li>
                      );
                    })}
                  </ul>

                  {!!linkGroupLevel1?.promos.length && (
                    <div
                      className={clsx([
                        'col-span-2',
                        'grid',
                        'grid-cols-3',
                        'auto-rows',
                        'gap-24',
                      ])}
                    >
                      {linkGroupLevel1.promos.map(
                        (linkGroupLevel1Promo, index) => {
                          return (
                            <ImageInterestPointCard
                              key={index}
                              href={linkGroupLevel1Promo.href}
                              image={linkGroupLevel1Promo.image}
                              orientation={{ desktop: 'column', mobile: 'row' }}
                            >
                              <p
                                className={clsx(
                                  'w-full',
                                  'p-24',
                                  'text-center',
                                  'text-14',
                                  'lg:text-16'
                                )}
                              >
                                {linkGroupLevel1Promo.caption}
                              </p>
                            </ImageInterestPointCard>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </BackgroundWrapper>

              {/* Link group level 3 */}
              <BackgroundWrapper
                background={linkGroupLevel2?.childBackground}
                wrapperProps={{
                  className: clsx([
                    'flex',
                    'justify-center',
                    'w-full',
                    'transition-all',
                    !isLinkGroupLevel3Expanded && [
                      'max-h-0',
                      'overflow-hidden',
                    ],
                    !!isLinkGroupLevel3Expanded && [
                      'max-h-screen',
                      'overflow-y-auto',
                    ],
                  ]),
                }}
                containerProps={{
                  className: clsx(['w-full', 'max-w-lg']),
                }}
              >
                <div
                  className={clsx([
                    'w-full',
                    'px-[27px]',
                    'py-8',
                    'lg:py-18',
                    linkGroupLevel2?.promos.length && [
                      'grid',
                      'grid-cols-3',
                      'gap-24',
                    ],
                  ])}
                >
                  <ul
                    className={clsx([
                      'navbar-v2-link-group-level-3',
                      'navbar-v2-link-group-level-3-desktop',
                      'w-full',
                      'flex',
                      'whitespace-nowrap',
                      'flex-wrap',
                      'list-none',
                      'p-0',
                      'm-0',
                      {
                        'gap-24': !(
                          linkGroupLevel2?.promos.length ||
                          childLinksDisplayStyle === 'column'
                        ),
                      },
                      {
                        'gap-10':
                          linkGroupLevel2?.promos.length ||
                          childLinksDisplayStyle === 'column',
                      },
                      {
                        'flex-col':
                          linkGroupLevel2?.promos.length ||
                          childLinksDisplayStyle === 'column',
                      },
                    ])}
                  >
                    {linkGroupLevel2?.children.map((level3Item, index) => {
                      const level3ItemKey = `${level3Item.label}-${index}`;

                      return (
                        <li key={level3ItemKey}>
                          <NavbarLinkItem
                            href={level3Item.href || '#'}
                            label={level3Item.label}
                            defaultInactiveColor={
                              linkGroupLevel2.childDefaultTextColor
                            }
                            inactiveTextColor={linkGroupLevel2.childTextColor}
                            defaultActiveColor={
                              linkGroupLevel2.childDefaultActiveTextColor
                            }
                            activeTextColor={
                              linkGroupLevel2.childActiveTextColor
                            }
                          />
                        </li>
                      );
                    })}
                  </ul>

                  {!!linkGroupLevel2?.promos.length && (
                    <div
                      className={clsx([
                        'col-span-2',
                        'grid',
                        'grid-cols-3',
                        'auto-rows',
                        'gap-24',
                      ])}
                    >
                      {linkGroupLevel2.promos.map(
                        (linkGroupLevel2Promo, index) => {
                          return (
                            <ImageInterestPointCard
                              key={index}
                              href={linkGroupLevel2Promo.href}
                              image={linkGroupLevel2Promo.image}
                              orientation={{ desktop: 'column', mobile: 'row' }}
                            >
                              <p
                                className={clsx(
                                  'w-full',
                                  'p-24',
                                  'text-center',
                                  'text-14',
                                  'lg:text-16'
                                )}
                              >
                                {linkGroupLevel2Promo.caption}
                              </p>
                            </ImageInterestPointCard>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </BackgroundWrapper>
            </>
          )}
        </div>

        {/* Mobile */}
        <div className={clsx(['flex', 'flex-col', 'lg:hidden', 'w-full'])}>
          {/* Navbar items */}
          <div
            className={clsx([
              'px-[15px]',
              'py-8',
              'w-full',
              'grid',
              'gap-10',
              'grid-cols-12',
              '[&>*]:self-center',
              '[&>*]:flex',
              '[&>*]:items-center',
            ])}
          >
            {/* Hamburger */}
            <div
              className={clsx([
                'col-span-3',
                'flex',
                'items-center',
                'gap-[6px]',
              ])}
            >
              <HamburgerButton
                size={mobile.hamburger.size}
                CustomIcon={
                  mobile.hamburger.type === 'custom'
                    ? ({ open }) => {
                        if (mobile.hamburger.type !== 'custom') return null;

                        return (
                          <>
                            <img
                              className={clsx(
                                'object-contain',
                                mobile.hamburger.size !== 'lg' && [
                                  'aspect-square',
                                  'object-contain',
                                ],
                                mobile.hamburger.size === 'lg' && ['h-full'],
                                { hidden: open }
                              )}
                              src={mobile.hamburger.open?.src}
                              loading="lazy"
                            />

                            <img
                              className={clsx(
                                'object-contain',
                                mobile.hamburger.size !== 'lg' && [
                                  'aspect-square',
                                  'object-contain',
                                ],
                                mobile.hamburger.size === 'lg' && ['h-full'],
                                { hidden: !open }
                              )}
                              src={mobile.hamburger.closed?.src}
                              loading="lazy"
                            />
                          </>
                        );
                      }
                    : false
                }
                onClick={handleHamburgerMenuClick}
                open={isHamburgerMenuOpen}
              />

              {!!searchMobileActionButton && (
                <NavbarActionButtonsItem
                  currency={currency}
                  locations={locations}
                  actionButtons={[searchMobileActionButton]}
                  align="left"
                />
              )}
            </div>

            {/* Logo and logotype */}
            <div className={clsx(['col-span-6', 'justify-center', 'h-full'])}>
              <HomeButton
                img={
                  mobile.logoStyle === 'dark'
                    ? site.logo.dark?.src
                    : site.logo.light?.src
                }
                size="sm"
                hideLogo={{ mobile: mobile.hideLogo }}
                hideLogotype={{ mobile: mobile.hideLogotype }}
                titleVariant={mobile.logotypeStyle}
                titleWeight={mobile.logotypeWeight}
                title={site.name}
                imgMaxHeight={40}
                fullWidth
              />
            </div>

            {/* First action (if present) and profile */}
            <div className={clsx(['col-span-3', 'justify-end'])}>
              <NavbarActionButtonsItem
                currency={currency}
                locations={locations}
                actionButtons={
                  firstVisibleMobileActionButtonsNavbarItem
                    ? [
                        ...(profileMobileActionButton
                          ? [profileMobileActionButton]
                          : []),
                        firstVisibleMobileActionButtonsNavbarItem,
                      ]
                    : undefined
                }
                align="right"
                containerClassName={clsx(['!gap-1'])}
              />
            </div>
          </div>

          {/* Link group level 1 */}
          <ul
            className={clsx([
              'navbar-v2-link-group-level-1',
              'navbar-v2-link-group-level-1-mobile',
              'list-none',
              'p-0',
              'm-0',
              'flex',
              'flex-col',
              'w-full',
              'transition-all',
              !isHamburgerMenuOpen && ['max-h-0', 'overflow-hidden'],
              !!isHamburgerMenuOpen && ['max-h-screen', 'overflow-y-auto'],
            ])}
          >
            {mobile.extraActionButtonsPosition !== 'belowLinks' &&
              !!(
                restVisibleMobileActionButtonsNavbarItems.length ||
                mobileVisibleCtaButtonNavbarItems.length
              ) && (
                <div
                  className={clsx([
                    'flex',
                    'flex-col',
                    'gap-10',
                    'p-16',
                    'border-t',
                    'border-background-secondary',
                  ])}
                >
                  <NavbarActionButtonsItem
                    currency={currency}
                    locations={locations}
                    actionButtons={restVisibleMobileActionButtonsNavbarItems.filter(
                      (actionButton) =>
                        actionButton.type !== 'search' &&
                        actionButton.type !== 'profile'
                    )}
                    align="left"
                  />

                  {mobileVisibleCtaButtonNavbarItems.map((ctaButton, index) => {
                    if (ctaButton.value.action.type === 'action') return null;

                    return (
                      <NavbarCtaButtonItem key={index} {...ctaButton.value} />
                    );
                  })}
                </div>
              )}

            {linkGroupLevel1Items.map(
              (linkGroupLevel1Item, linkGroupLevel1Index) => {
                const linkGroupLevel1ItemKey = `linkGroupLevel1Item-${linkGroupLevel1Index}`;

                return (
                  <li key={linkGroupLevel1ItemKey}>
                    <div
                      className={clsx([
                        'p-16',
                        'border-t',
                        'border-background-secondary',
                      ])}
                    >
                      <NavbarLinkItem
                        href={linkGroupLevel1Item.href || '#'}
                        label={linkGroupLevel1Item.label}
                        onClick={() =>
                          handleLinkGroupLevel1Click(linkGroupLevel1Item)
                        }
                        linkGroupLevel1={linkGroupLevel1Item}
                        expanded={
                          JSON.stringify(linkGroupLevel1) ===
                            JSON.stringify(linkGroupLevel1Item) &&
                          isLinkGroupLevel2Expanded
                        }
                        defaultInactiveColor={
                          linkGroupLevel1Item.defaultTextColor ||
                          defaultInactiveTextColor
                        }
                        inactiveTextColor={
                          linkGroupLevel1Item.textColor || inactiveTextColor
                        }
                        defaultActiveColor={
                          linkGroupLevel1Item.defaultActiveTextColor ||
                          defaultActiveTextColor
                        }
                        activeTextColor={
                          linkGroupLevel1Item.activeTextColor || activeTextColor
                        }
                        fullWidth
                      />
                    </div>

                    {/* Link group level 2 */}
                    {!!linkGroupLevel1Item.children.length && (
                      <ul
                        className={clsx([
                          'navbar-v2-link-group-level-2',
                          'navbar-v2-link-group-level-2-mobile',
                          'list-none',
                          'p-0',
                          'm-0',
                          'flex',
                          'flex-col',
                          'w-full',
                          'transition-all',
                          !(
                            JSON.stringify(linkGroupLevel1) ===
                              JSON.stringify(linkGroupLevel1Item) &&
                            isLinkGroupLevel2Expanded
                          ) && ['max-h-0', 'overflow-hidden'],
                          JSON.stringify(linkGroupLevel2) ===
                            JSON.stringify(linkGroupLevel1Item) &&
                            isLinkGroupLevel3Expanded && [
                              'max-h-screen',
                              'overflow-y-auto',
                            ],
                        ])}
                      >
                        {linkGroupLevel1Item.children.map(
                          (linkGroupLevel2Item, linkGroupLevel2Index) => {
                            const linkGroupLevel2ItemKey = `linkGroupLevel2Item-${linkGroupLevel2Index}`;

                            return (
                              <li key={linkGroupLevel2ItemKey}>
                                <BackgroundWrapper
                                  background={linkGroupLevel1?.childBackground}
                                  wrapperProps={{
                                    className: clsx([
                                      'p-16',
                                      'pl-32',
                                      'border-t',
                                      'border-background-primary',
                                      'bg-background-secondary',
                                    ]),
                                  }}
                                >
                                  <NavbarLinkItem
                                    href={linkGroupLevel2Item.href || '#'}
                                    label={linkGroupLevel2Item.label}
                                    hasChildren={
                                      !!linkGroupLevel2Item.children?.length
                                    }
                                    onClick={() =>
                                      handleLinkGroupLevel2Click(
                                        linkGroupLevel2Item
                                      )
                                    }
                                    expanded={
                                      JSON.stringify(linkGroupLevel2) ===
                                        JSON.stringify(linkGroupLevel2Item) &&
                                      isLinkGroupLevel3Expanded
                                    }
                                    fontSize={12}
                                    defaultInactiveColor={
                                      linkGroupLevel1?.childDefaultTextColor
                                    }
                                    inactiveTextColor={
                                      linkGroupLevel1?.childTextColor
                                    }
                                    defaultActiveColor={
                                      linkGroupLevel1?.childDefaultActiveTextColor
                                    }
                                    activeTextColor={
                                      linkGroupLevel1?.childActiveTextColor
                                    }
                                    fullWidth
                                  />
                                </BackgroundWrapper>

                                {/* Link group level 3 */}
                                {!!linkGroupLevel2Item.children.length && (
                                  <ul
                                    className={clsx([
                                      'navbar-v2-link-group-level-3',
                                      'navbar-v2-link-group-level-3-mobile',
                                      'list-none',
                                      'p-0',
                                      'm-0',
                                      'flex',
                                      'flex-col',
                                      'w-full',
                                      'transition-all',
                                      !(
                                        JSON.stringify(linkGroupLevel2) ===
                                          JSON.stringify(linkGroupLevel2Item) &&
                                        isLinkGroupLevel3Expanded
                                      ) && ['max-h-0', 'overflow-hidden'],
                                      JSON.stringify(linkGroupLevel2) ===
                                        JSON.stringify(linkGroupLevel2Item) &&
                                        isLinkGroupLevel3Expanded && [
                                          'max-h-screen',
                                          'overflow-y-auto',
                                        ],
                                    ])}
                                  >
                                    {linkGroupLevel2Item.children.map(
                                      (
                                        linkGroupLevel3Item,
                                        linkGroupLevel3Index
                                      ) => {
                                        const linkGroupLevel3ItemKey = `linkGroupLevel3Item-${linkGroupLevel3Index}`;

                                        return (
                                          <BackgroundWrapper
                                            key={linkGroupLevel3ItemKey}
                                            background={
                                              linkGroupLevel2?.childBackground
                                            }
                                            wrapperProps={{
                                              className: clsx([
                                                'p-16',
                                                'pl-48',
                                                'border-t',
                                                'border-background-secondary',
                                                'bg-background-secondary',
                                              ]),
                                            }}
                                          >
                                            <li>
                                              <NavbarLinkItem
                                                href={
                                                  linkGroupLevel3Item.href ||
                                                  '#'
                                                }
                                                label={
                                                  linkGroupLevel3Item.label
                                                }
                                                fontSize={12}
                                                defaultInactiveColor={
                                                  linkGroupLevel2?.childDefaultTextColor
                                                }
                                                inactiveTextColor={
                                                  linkGroupLevel2?.childTextColor
                                                }
                                                defaultActiveColor={
                                                  linkGroupLevel2?.childDefaultActiveTextColor
                                                }
                                                activeTextColor={
                                                  linkGroupLevel2?.childActiveTextColor
                                                }
                                                fullWidth
                                              />
                                            </li>
                                          </BackgroundWrapper>
                                        );
                                      }
                                    )}
                                  </ul>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </li>
                );
              }
            )}

            {!!(
              mobile.extraActionButtonsPosition === 'belowLinks' &&
              !!(
                restVisibleMobileActionButtonsNavbarItems.length ||
                mobileVisibleCtaButtonNavbarItems.length
              )
            ) && (
              <div
                className={clsx([
                  'flex',
                  'flex-col',
                  'gap-10',
                  'p-16',
                  'border-t',
                  'border-background-secondary',
                ])}
              >
                <NavbarActionButtonsItem
                  currency={currency}
                  locations={locations}
                  actionButtons={restVisibleMobileActionButtonsNavbarItems}
                  align="left"
                />

                {mobileVisibleCtaButtonNavbarItems.map((ctaButton, index) => (
                  <NavbarCtaButtonItem key={index} {...ctaButton.value} />
                ))}
              </div>
            )}
          </ul>

          {/* Action buttons */}

          {/* Promos */}
        </div>
      </nav>
    </BackgroundWrapper>
  );
};
