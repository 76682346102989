import { DrawersProvider } from '@/contexts';
import type {
  Location,
  NavbarVersion3 as NavbarVersion3Type,
  SiteType,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

import { NavbarBannerSection, NavbarNavbarSection } from './sections';

export type NavbarVersion3Props = NavbarVersion3Type['value'] & {
  locations?: Location[];
  currency?: string;
  backHref?: string;
  badgeConfigs?: SiteType<'homeDecor1'>['productBadges'];
  site: SiteType<'homeDecor1'>;
};

export const NavbarVersion3: FC<NavbarVersion3Props> = ({
  locations,
  sections,
  backHref,
  currency,
  badgeConfigs,
  site,
}) => {
  return (
    <DrawersProvider
      locations={locations}
      backHref={backHref}
      badgeConfigs={badgeConfigs}
      currency={currency}
    >
      <div
        className={clsx([
          'fixed',
          'top-0',
          'left-0',
          'w-full',
          'z-[500]',
          'h-screen',
          'lg:left-0',
          'lg:min-w-[280px]',
          'lg:max-w-[280px]',
          'lg:sticky',
          'overflow-y-auto',
        ])}
      >
        {sections.map((section, index) => {
          const key = `${section.type}-${index}`;

          switch (section.type) {
            case 'banner':
              return (
                <div className={clsx(['w-full', 'lg:hidden'])}>
                  <NavbarBannerSection key={key} {...section.value} />
                </div>
              );
            case 'navbar':
              return (
                <NavbarNavbarSection
                  key={key}
                  locations={locations}
                  site={site}
                  {...section.value}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </DrawersProvider>
  );
};
